import * as React from 'react';
import copy from 'copy-to-clipboard';
import { useState } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BackspaceIcon from '@mui/icons-material/Backspace';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import {
    listVersion,
    listAspectRatio,
    listMedium,
    listCamera,
    listLighting,
    listColor,
    listDescriptor,
    listArtist,
    listFilm,
    listGame,
    listDepthOfField,
} from '~/asset/files/data.js';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function PromptBuilder() {
    // Khoi tao text prompt (dang object)
    const [promptText, setPromptText] = useState({});
    // Khoi tao do not include (dang object)
    const [doNotIncludes, setDoNotIncludes] = useState({});
    const [paramDDCLCs, setparamDDCLCs] = useState({});

    const [open, setOpen] = React.useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const mappingPromptTextResult = () => {
        return (
            '/imagine prompt:' +
            (promptText.urlImage1 ? ' ' + promptText.urlImage1 : '') +
            (promptText.urlImage2 ? ' ' + promptText.urlImage2 : '') +
            (promptText.urlImage3 ? ' ' + promptText.urlImage3 : '') +
            (promptText.writtenWord ? ' ' + promptText.writtenWord + '(written word)' : '') +
            (promptText.writtenWordWeight ? '::' + promptText.writtenWordWeight : '') +
            (promptText.paramMedium ? ' ' + promptText.paramMedium + ' of' : '') +
            (promptText.mainIdea ? ' ' + promptText.mainIdea : '') +
            (promptText.mainIdeaWeight ? '::' + promptText.mainIdeaWeight : '') +
            (promptText.paramArtist ? ' by ' + promptText.paramArtist : '') +
            (promptText.paramFilm ? ' in the style of the movie ' + promptText.paramFilm : '') +
            (promptText.paramGame ? ' in the style of the video game ' + promptText.paramGame : '') +
            (promptText.secondarySubject1
                ? (promptText.secondarySubjectWeight1 ? ' +::' : '') + ' ' + promptText.secondarySubject1
                : '') +
            (promptText.secondarySubjectWeight1 ? '::' + promptText.secondarySubjectWeight1 : '') +
            (promptText.secondarySubject2
                ? (promptText.secondarySubjectWeight2 ? ' +::' : '') + ' ' + promptText.secondarySubject2
                : '') +
            (promptText.secondarySubjectWeight2 ? '::' + promptText.secondarySubjectWeight2 : '') +
            (promptText.secondarySubject3
                ? (promptText.secondarySubjectWeight3 ? ' +::' : '') + ' ' + promptText.secondarySubject3
                : '') +
            (promptText.secondarySubjectWeight3 ? '::' + promptText.secondarySubjectWeight3 : '') +
            (promptText.secondarySubject4
                ? (promptText.secondarySubjectWeight4 ? ' +::' : '') + ' ' + promptText.secondarySubjectWeight4
                : '') +
            (promptText.secondarySubjectWeight4 ? '::' + promptText.secondarySubjectWeight4 : '') +
            (promptText.secondarySubject5
                ? (promptText.secondarySubjectWeight5 ? ' +::' : '') + ' ' + promptText.secondarySubject5
                : '') +
            (promptText.secondarySubjectWeight5 ? '::' + promptText.secondarySubjectWeight5 : '') +
            (promptText.depthOfField === 'dof' ? ' dof' : '') +
            (Object.values(paramDDCLCs).length > 0 ? ' ' + Object.values(paramDDCLCs).toString() : '') +
            (Object.values(doNotIncludes).length > 0
                ? ' --no ' +
                  (promptText.depthOfField === 'defocus,' ? ' defocus,' : '') +
                  Object.values(doNotIncludes).toString()
                : '') +
            (promptText.paramUpBeta ? ' --upbeta' : '') +
            (promptText.paramUpLight ? ' --uplight' : '') +
            (promptText.doNotIncludeStop ? ' --stop ' + promptText.doNotIncludeStop : '') +
            (promptText.paramAspectRatio ? ' --ar ' + promptText.paramAspectRatio : '') +
            (promptText.paramSeed ? ' --seed ' + promptText.paramSeed : '') +
            (promptText.paramStylize ? ' --stylize ' + promptText.paramStylize : '') +
            (promptText.paramQuality ? ' --quality ' + promptText.paramQuality : '') +
            (promptText.paramChaos ? ' --chaos ' + promptText.paramChaos : '') +
            (promptText.paramStyle ? ' --s ' + promptText.paramStyle : '') +
            (promptText.paramVersion ? promptText.paramVersion : '')
        );
    };

    // Main idea
    const [mainIdea, setMainIdea] = useState('');
    const handleChange_mainIdea = (event) => {
        setMainIdea(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ mainIdea: event.target.value },
        }));
    };
    const [mainIdeaWeight, setMainIdeaWeight] = useState('');
    const handleChange_mainIdeaWeight = (event) => {
        setMainIdeaWeight(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ mainIdeaWeight: event.target.value },
        }));
    };

    //Secondary Subjects
    const [isShowSecondarySubject, setIsShowSecondarySubject] = useState(false);
    const handleChange_isShowSecondarySubject = (event) => {
        setIsShowSecondarySubject(event.target.checked);
    };

    const [secondarySubject1, setSecondarySubject1] = useState('');
    const handleChange_secondarySubject1 = (event) => {
        setSecondarySubject1(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ secondarySubject1: event.target.value },
        }));
    };
    const [secondarySubjectWeight1, setSecondarySubjectWeight1] = useState('');
    const handleChange_secondarySubjectWeight1 = (event) => {
        setSecondarySubjectWeight1(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ secondarySubjectWeight1: event.target.value },
        }));
    };

    const [secondarySubject2, setSecondarySubject2] = useState('');
    const handleChange_secondarySubject2 = (event) => {
        setSecondarySubject2(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ secondarySubject2: event.target.value },
        }));
    };
    const [secondarySubjectWeight2, setSecondarySubjectWeight2] = useState('');
    const handleChange_secondarySubjectWeight2 = (event) => {
        setSecondarySubjectWeight2(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ secondarySubjectWeight2: event.target.value },
        }));
    };

    const [secondarySubject3, setSecondarySubject3] = useState('');
    const handleChange_secondarySubject3 = (event) => {
        setSecondarySubject3(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ secondarySubject3: event.target.value },
        }));
    };
    const [secondarySubjectWeight3, setSecondarySubjectWeight3] = useState('');
    const handleChange_secondarySubjectWeight3 = (event) => {
        setSecondarySubjectWeight3(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ secondarySubjectWeight3: event.target.value },
        }));
    };

    const [secondarySubject4, setSecondarySubject4] = useState('');
    const handleChange_secondarySubject4 = (event) => {
        setSecondarySubject4(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ secondarySubject4: event.target.value },
        }));
    };
    const [secondarySubjectWeight4, setSecondarySubjectWeight4] = useState('');
    const handleChange_secondarySubjectWeight4 = (event) => {
        setSecondarySubjectWeight4(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ secondarySubjectWeight4: event.target.value },
        }));
    };

    const [secondarySubject5, setSecondarySubject5] = useState('');
    const handleChange_secondarySubject5 = (event) => {
        setSecondarySubject5(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ secondarySubject5: event.target.value },
        }));
    };
    const [secondarySubjectWeight5, setSecondarySubjectWeight5] = useState('');
    const handleChange_secondarySubjectWeight5 = (event) => {
        setSecondarySubjectWeight5(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ secondarySubjectWeight5: event.target.value },
        }));
    };

    // Url images
    const [isShowImages, setIsShowImages] = useState(false);
    const handleChange_isShowImages = (event) => {
        setIsShowImages(event.target.checked);
    };

    const [urlImage1, setUrlImage1] = useState('');
    const handleChange_urlImage1 = (event) => {
        setUrlImage1(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ urlImage1: event.target.value },
        }));
    };
    const [urlImage2, setUrlImage2] = useState('');
    const handleChange_urlImage2 = (event) => {
        setUrlImage2(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ urlImage2: event.target.value },
        }));
    };
    const [urlImage3, setUrlImage3] = useState('');
    const handleChange_urlImage3 = (event) => {
        setUrlImage3(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ urlImage3: event.target.value },
        }));
    };

    //doNotIncludes
    const [isShowDoNotIncludes, setIsShowDoNotIncludes] = useState(false);
    const handleChange_isShowDoNotIncludes = (event) => {
        setIsShowDoNotIncludes(event.target.checked);
    };

    const [doNotInclude1, setDoNotInclude1] = useState('');
    const handleChange_doNotInclude1 = (event) => {
        setDoNotInclude1(event.target.value);
        setDoNotIncludes((doNotIncludes) => ({
            ...doNotIncludes,
            ...{ doNotInclude1: event.target.value },
        }));
    };
    const [doNotInclude2, setDoNotInclude2] = useState('');
    const handleChange_doNotInclude2 = (event) => {
        setDoNotInclude2(event.target.value);
        setDoNotIncludes((doNotIncludes) => ({
            ...doNotIncludes,
            ...{ doNotInclude2: event.target.value },
        }));
    };
    const [doNotInclude3, setDoNotInclude3] = useState('');
    const handleChange_doNotInclude3 = (event) => {
        setDoNotInclude3(event.target.value);
        setDoNotIncludes((doNotIncludes) => ({
            ...doNotIncludes,
            ...{ doNotInclude3: event.target.value },
        }));
    };

    const [doNotIncludeStop, setDoNotIncludeStop] = useState('');
    const handleChange_doNotIncludeStop = (event) => {
        setDoNotIncludeStop(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ doNotIncludeStop: event.target.value },
        }));
    };

    // param

    const [paramVersion, setParamVersion] = useState('');
    const handleChange_paramVersion = (event) => {
        setParamVersion(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ paramVersion: event.target.value },
        }));
    };

    const [paramStylize, setParamStylize] = useState('');
    const handleChange_paramStylize = (event) => {
        setParamStylize(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ paramStylize: event.target.value },
        }));
    };
    const [paramQuality, setParamQuality] = useState('');
    const handleChange_paramQuality = (event) => {
        setParamQuality(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ paramQuality: event.target.value },
        }));
    };
    const [paramSeed, setParamSeed] = useState('');
    const handleChange_paramSeed = (event) => {
        setParamSeed(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ paramSeed: event.target.value },
        }));
    };
    const [paramChaos, setParamChaos] = useState(50);
    const handleChange_paramChaos = (event) => {
        setParamChaos(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ paramChaos: event.target.value },
        }));
    };

    const [paramStyle, setParamStyle] = useState('');
    const handleChange_paramStyle = (event) => {
        setParamStyle(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ paramStyle: event.target.value },
        }));
    };

    const [paramUpBeta, setParamUpBeta] = useState(false);
    const handleChange_paramUpBeta = (event) => {
        setParamUpBeta(event.target.checked);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ paramUpBeta: event.target.checked },
        }));
    };

    const [paramUpLight, setParamUpLight] = useState(false);
    const handleChange_paramUpLight = (event) => {
        setParamUpLight(event.target.checked);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ paramUpLight: event.target.checked },
        }));
    };

    // Aspect Ratio
    const [paramAspectRatio, setParamAspectRatio] = useState('');
    function handleChange_paramAspectRatio(event) {
        setParamAspectRatio(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ paramAspectRatio: event.target.value },
        }));
    }

    // Medium
    const [paramMedium, setParamMedium] = useState('');
    function handleChange_paramMedium(event) {
        setParamMedium(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ paramMedium: event.target.value },
        }));
    }

    // Camera
    const [paramCamera, setParamCamera] = useState('');
    function handleChange_paramCamera(event) {
        setParamCamera(event.target.value);
        setparamDDCLCs((paramDDCLCs) => ({
            ...paramDDCLCs,
            ...{ paramCamera: event.target.value },
        }));
    }

    // Lighting
    const [paramLighting, setParamLighting] = useState('');
    function handleChange_paramLighting(event) {
        setParamLighting(event.target.value);
        setparamDDCLCs((paramDDCLCs) => ({
            ...paramDDCLCs,
            ...{ paramLighting: event.target.value },
        }));
    }

    // Color
    const [paramColor, setParamColor] = useState('');
    function handleChange_paramColor(event) {
        setParamColor(event.target.value);
        setparamDDCLCs((paramDDCLCs) => ({
            ...paramDDCLCs,
            ...{ paramColor: event.target.value },
        }));
    }

    // Descriptor
    const [paramDescriptor, setParamDescriptor] = useState('');
    function handleChange_paramDescriptor(event) {
        setParamDescriptor(event.target.value);
        setparamDDCLCs((paramDDCLCs) => ({
            ...paramDDCLCs,
            ...{ paramDescriptor: event.target.value },
        }));
    }

    const [paramDescriptor2, setParamDescriptor2] = useState('');
    function handleChange_paramDescriptor2(event) {
        setParamDescriptor2(event.target.value);
        setparamDDCLCs((paramDDCLCs) => ({
            ...paramDDCLCs,
            ...{ paramDescriptor2: event.target.value },
        }));
    }

    // artist
    const [paramArtist, setParamArtist] = useState('');
    function handleChange_paramArtist(event) {
        setParamArtist(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ paramArtist: event.target.value },
        }));
    }

    // Film
    const [paramFilm, setParamFilm] = useState('');
    function handleChange_paramFilm(event) {
        setParamFilm(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ paramFilm: event.target.value },
        }));
    }

    // Game
    const [paramGame, setParamGame] = useState('');
    function handleChange_paramGame(event) {
        setParamGame(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ paramGame: event.target.value },
        }));
    }

    // Game
    const [depthOfField, setDepthOfField] = useState('');
    function handleChange_depthOfField(event) {
        setDepthOfField(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ depthOfField: event.target.value },
        }));
    }

    // writtenWord
    const [writtenWord, setWrittenWord] = useState('');
    function handleChange_writtenWord(event) {
        setWrittenWord(event.target.value.toUpperCase());
        setPromptText((promptText) => ({
            ...promptText,
            ...{ writtenWord: event.target.value.toUpperCase() },
        }));
    }
    const [writtenWordWeight, setWrittenWordWeight] = useState('');
    function handleChange_writtenWordWeight(event) {
        setWrittenWordWeight(event.target.value);
        setPromptText((promptText) => ({
            ...promptText,
            ...{ writtenWordWeight: event.target.value },
        }));
    }

    const copyToClipboard = () => {
        copy(mappingPromptTextResult());
        setOpen(true);
    };

    const clearPromptText = () => {
        setPromptText((promptText) => ({}));
        setMainIdea('');
        setMainIdeaWeight('');
        setSecondarySubject1('');
        setSecondarySubjectWeight1('');
        setSecondarySubject2('');
        setSecondarySubjectWeight2('');
        setSecondarySubject3('');
        setSecondarySubjectWeight3('');
        setSecondarySubject4('');
        setSecondarySubjectWeight4('');
        setSecondarySubject5('');
        setSecondarySubjectWeight5('');
        setUrlImage1('');
        setUrlImage2('');
        setUrlImage3('');
        setDoNotInclude1('');
        setDoNotIncludes((doNotIncludes) => ({}));
        setDoNotInclude2('');
        setDoNotInclude3('');
        setDoNotIncludeStop('');
        setParamVersion('');
        setParamStylize('');
        setParamQuality('');
        setParamSeed('');
        setParamChaos(0);
        setParamStyle('');
        setParamUpBeta(false);
        setParamUpLight(false);
        setParamAspectRatio('');
        setParamMedium('');
        setParamCamera('');
        setParamLighting('');
        setParamColor('');
        setParamDescriptor('');
        setParamDescriptor2('');
        setparamDDCLCs((paramDDCLCs) => ({}));
        setParamArtist('');
        setParamFilm('');
        setParamGame('');
        setDepthOfField('');
        setWrittenWord('');
        setWrittenWordWeight('');
    };

    return (
        <>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    You copied this text to your clipboard!
                </Alert>
            </Snackbar>
            <main>
                {/* Hero unit */}
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 6,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="lg">
                        <Typography component="h1" variant="h2" align="center" color="text.primary" gutterBottom>
                            Prompter for MidJourney v3.2
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            Something short and leading about the collection below—its contents, the creator, etc. Make
                            it short and sweet, but not too short so folks don&apos;t simply skip over it entirely.
                        </Typography>
                    </Container>

                    <Container sx={{ py: 1 }} maxWidth="lg">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h5" color="primary" align="left">
                                    Main Idea
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    id="main-idea"
                                    label="What's the main idea?"
                                    variant="standard"
                                    fullWidth
                                    value={mainIdea}
                                    onChange={handleChange_mainIdea}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    id="main-idea-weight"
                                    label="Weight"
                                    variant="standard"
                                    fullWidth
                                    type="number"
                                    value={mainIdeaWeight}
                                    onChange={handleChange_mainIdeaWeight}
                                />
                            </Grid>
                        </Grid>

                        <Grid container mt={4} spacing={1}>
                            <Grid item xs={12} md={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label="Secondary Subjects"
                                        color="warning"
                                        checked={isShowSecondarySubject}
                                        onChange={handleChange_isShowSecondarySubject}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        {isShowSecondarySubject && (
                            <>
                                <Grid container mb={4} spacing={2}>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            id="secondary-subject-1"
                                            label="Secondary Subject 1"
                                            variant="standard"
                                            fullWidth
                                            onChange={handleChange_secondarySubject1}
                                            value={secondarySubject1}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="secondary-subject-weight-1"
                                            label="Weight"
                                            variant="standard"
                                            fullWidth
                                            type="number"
                                            onChange={handleChange_secondarySubjectWeight1}
                                            value={secondarySubjectWeight1}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            id="secondary-subject-2"
                                            label="Secondary Subject 2"
                                            variant="standard"
                                            fullWidth
                                            onChange={handleChange_secondarySubject2}
                                            value={secondarySubject2}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="secondary-subject-weight-2"
                                            label="Weight"
                                            variant="standard"
                                            fullWidth
                                            type="number"
                                            onChange={handleChange_secondarySubjectWeight2}
                                            value={secondarySubjectWeight2}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            id="secondary-subject-3"
                                            label="Secondary Subject 3"
                                            variant="standard"
                                            fullWidth
                                            onChange={handleChange_secondarySubject3}
                                            value={secondarySubject3}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="secondary-subject-weight-3"
                                            label="Weight"
                                            variant="standard"
                                            fullWidth
                                            type="number"
                                            onChange={handleChange_secondarySubjectWeight3}
                                            value={secondarySubjectWeight3}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            id="secondary-subject-4"
                                            label="Secondary Subject 4"
                                            variant="standard"
                                            fullWidth
                                            onChange={handleChange_secondarySubject4}
                                            value={secondarySubject4}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="secondary-subject-weight-4"
                                            label="Weight"
                                            variant="standard"
                                            fullWidth
                                            type="number"
                                            onChange={handleChange_secondarySubjectWeight4}
                                            value={secondarySubjectWeight4}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            id="secondary-subject-5"
                                            label="Secondary Subject 5"
                                            variant="standard"
                                            fullWidth
                                            onChange={handleChange_secondarySubject5}
                                            value={secondarySubject5}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="secondary-subject-weight-5"
                                            label="Weight"
                                            variant="standard"
                                            fullWidth
                                            type="number"
                                            onChange={handleChange_secondarySubjectWeight5}
                                            value={secondarySubjectWeight5}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        <Grid container mt={0} spacing={1}>
                            <Grid item xs={12} md={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label="Url image(s) as visual inspiration"
                                        color="warning"
                                        checked={isShowImages}
                                        onChange={handleChange_isShowImages}
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        {isShowImages && (
                            <>
                                {' '}
                                <Grid container mb={4} spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id="url-image-1"
                                            label="Url image 1"
                                            variant="standard"
                                            fullWidth
                                            value={urlImage1}
                                            onChange={handleChange_urlImage1}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id="url-image-2"
                                            label="Url image 2"
                                            variant="standard"
                                            fullWidth
                                            value={urlImage2}
                                            onChange={handleChange_urlImage2}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id="url-image-3"
                                            label="Url image 3"
                                            variant="standard"
                                            fullWidth
                                            value={urlImage3}
                                            onChange={handleChange_urlImage3}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        <Grid container mt={0} spacing={1}>
                            <Grid item xs={12} md={12}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label="Do not include"
                                        color="warning"
                                        checked={isShowDoNotIncludes}
                                        onChange={handleChange_isShowDoNotIncludes}
                                    />
                                </FormGroup>
                            </Grid>

                            {isShowDoNotIncludes && (
                                <>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id="do-not-include-1"
                                            label="Something 1"
                                            variant="standard"
                                            fullWidth
                                            value={doNotInclude1}
                                            onChange={handleChange_doNotInclude1}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id="do-not-include-2"
                                            label="Something 2"
                                            variant="standard"
                                            fullWidth
                                            value={doNotInclude2}
                                            onChange={handleChange_doNotInclude2}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id="do-not-include-3"
                                            label="Something 3"
                                            variant="standard"
                                            fullWidth
                                            value={doNotInclude3}
                                            onChange={handleChange_doNotInclude3}
                                        />
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12} md={12}>
                                <TextField
                                    id="do-not-include-stop"
                                    label="Stop (render at 10-99)"
                                    variant="standard"
                                    fullWidth
                                    type="number"
                                    value={doNotIncludeStop}
                                    onChange={handleChange_doNotIncludeStop}
                                />
                            </Grid>
                        </Grid>

                        <Grid container mt={4} rowSpacing={2} columnSpacing={4}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h5" color="primary">
                                    Algorithm & Parameters
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box>
                                    <FormControl fullWidth>
                                        <InputLabel id="param-version-input-label">Version Of Algorithm</InputLabel>
                                        <Select
                                            labelId="param-version-label"
                                            id="param-version"
                                            value={paramVersion}
                                            onChange={handleChange_paramVersion}
                                            variant="standard"
                                        >
                                            {listVersion.map((item) => (
                                                <MenuItem key={item.id} value={item.value}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box mt={2}>
                                    <TextField
                                        id="param-stylize"
                                        label="Stylize (0 to 1000, 100 is default)"
                                        variant="standard"
                                        fullWidth
                                        type="number"
                                        value={paramStylize}
                                        onChange={handleChange_paramStylize}
                                        InputProps={{ inputProps: { min: 0, max: 1000 } }}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="param-quality-input-label">
                                            Quality (0.5 quick, 1 default, 2 current max)
                                        </InputLabel>
                                        <Select
                                            labelId="param-quality-label"
                                            id="param-quality"
                                            value={paramQuality}
                                            onChange={handleChange_paramQuality}
                                            variant="standard"
                                        >
                                            <MenuItem key="0" value="">
                                                Select quality
                                            </MenuItem>
                                            <MenuItem key="1" value="0.5">
                                                0.5
                                            </MenuItem>
                                            <MenuItem key="2" value="1">
                                                1
                                            </MenuItem>
                                            <MenuItem key="3" value="2">
                                                2
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box mt={2}>
                                    <Typography id="chao-input-slider" gutterBottom>
                                        Chaos (0 to 100, more chaotic / abstract - 0 is disabled)
                                    </Typography>
                                    <Slider
                                        id="param-chao"
                                        defaultValue={50}
                                        value={paramChaos}
                                        onChange={handleChange_paramChaos}
                                        aria-labelledby="input-slider"
                                        label="abc"
                                        aria-label="Default"
                                        valueLabelDisplay="auto"
                                    />
                                </Box>
                                <Box mt={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="param-style-input-label">
                                            Style (50 low, 100 default, 250 high, 750 very high)
                                        </InputLabel>
                                        <Select
                                            labelId="param-style-label"
                                            id="param-style"
                                            value={paramStyle}
                                            onChange={handleChange_paramStyle}
                                            variant="standard"
                                        >
                                            <MenuItem key="0" value="">
                                                Select style
                                            </MenuItem>
                                            <MenuItem key="1" value="50">
                                                50 (style low)
                                            </MenuItem>
                                            <MenuItem key="2" value="100">
                                                100 (default)
                                            </MenuItem>
                                            <MenuItem key="3" value="250">
                                                250 (high)
                                            </MenuItem>
                                            <MenuItem key="4" value="750">
                                                750 (very high)
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box mt={1}>
                                    <TextField
                                        id="param-seed"
                                        label="Seed (variations use the same style)"
                                        variant="standard"
                                        fullWidth
                                        value={paramSeed}
                                        onChange={handleChange_paramSeed}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Version</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  label="Age"
                  onChange={handleChange}
                  variant="standard"
                >
                  <MenuItem value={1}>V1 (vibe for textures, macro)</MenuItem>
                  <MenuItem value={2}>V2 (old algorithm, more abstract)</MenuItem>
                  <MenuItem value={3}>V3 (current version)</MenuItem>
                  <MenuItem value={4}>Test (new, general artistic mode)</MenuItem>
                </Select>
              </FormControl> */}

                                <Box mt={1}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label="UpBeta (new feature, bigger, smooth details)"
                                            color="warning"
                                            checked={paramUpBeta}
                                            onChange={handleChange_paramUpBeta}
                                        />
                                    </FormGroup>
                                </Box>
                                <Box mt={3}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label="UpLight (smaller, smoother, less detail)"
                                            color="secondary"
                                            checked={paramUpLight}
                                            onChange={handleChange_paramUpLight}
                                        />
                                    </FormGroup>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container mt={4} rowSpacing={2} columnSpacing={4}>
                            <Grid item xs={12} py={1} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="aspect-ratio">Aspect Ratio</InputLabel>
                                    <Select
                                        labelId="aspect-ratio-label"
                                        id="aspect-ratio"
                                        value={paramAspectRatio}
                                        label="Aspect Ratio"
                                        onChange={handleChange_paramAspectRatio}
                                        variant="standard"
                                    >
                                        {listAspectRatio.map((item) => (
                                            <MenuItem key={item.id} value={item.value}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} py={1} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="medium">Medium</InputLabel>
                                    <Select
                                        labelId="medium-label"
                                        id="medium"
                                        label="Medium"
                                        variant="standard"
                                        value={paramMedium}
                                        onChange={handleChange_paramMedium}
                                    >
                                        {listMedium.map((item) => (
                                            <MenuItem key={item.id} value={item.value}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} py={1} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="camera">Camera</InputLabel>
                                    <Select
                                        labelId="camera-label"
                                        id="camera"
                                        value={paramCamera}
                                        label="Camera"
                                        onChange={handleChange_paramCamera}
                                        variant="standard"
                                    >
                                        {listCamera.map((item) => (
                                            <MenuItem key={item.id} value={item.value}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} py={1} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="Lighting">Lighting</InputLabel>
                                    <Select
                                        labelId="Lighting"
                                        id="Lighting"
                                        value={paramLighting}
                                        label="Lighting"
                                        onChange={handleChange_paramLighting}
                                        variant="standard"
                                    >
                                        {listLighting.map((item) => (
                                            <MenuItem key={item.id} value={item.value}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} py={1} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="color">Color</InputLabel>
                                    <Select
                                        labelId="color-label"
                                        id="color"
                                        value={paramColor}
                                        label="Color"
                                        onChange={handleChange_paramColor}
                                        variant="standard"
                                    >
                                        {listColor.map((item) => (
                                            <MenuItem key={item.id} value={item.value}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} py={1} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="Descriptor">Descriptor</InputLabel>
                                    <Select
                                        labelId="Descriptor-label"
                                        id="Descriptor"
                                        value={paramDescriptor}
                                        label="Descriptor"
                                        onChange={handleChange_paramDescriptor}
                                        variant="standard"
                                    >
                                        {listDescriptor.map((item) => (
                                            <MenuItem key={item.id} value={item.value}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} py={1} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="Descriptor-2">Descriptor 2</InputLabel>
                                    <Select
                                        labelId="Descriptor-2-label"
                                        id="Descriptor-2"
                                        value={paramDescriptor2}
                                        label="Descriptor 2"
                                        onChange={handleChange_paramDescriptor2}
                                        variant="standard"
                                    >
                                        {listDescriptor.map((item) => (
                                            <MenuItem key={item.id} value={item.value}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} py={1} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="Artist">Artist</InputLabel>
                                    <Select
                                        labelId="Artist-label"
                                        id="Artist"
                                        value={paramArtist}
                                        label="Artist"
                                        onChange={handleChange_paramArtist}
                                        variant="standard"
                                    >
                                        {listArtist.map((item) => (
                                            <MenuItem key={item.id} value={item.value}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} py={1} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="Film">Film</InputLabel>
                                    <Select
                                        labelId="Film-label"
                                        id="Film"
                                        value={paramFilm}
                                        label="Film"
                                        onChange={handleChange_paramFilm}
                                        variant="standard"
                                    >
                                        {listFilm.map((item) => (
                                            <MenuItem key={item.id} value={item.value}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} py={1} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="Games">Games</InputLabel>
                                    <Select
                                        labelId="Games-label"
                                        id="Games"
                                        value={paramGame}
                                        label="Games"
                                        onChange={handleChange_paramGame}
                                        variant="standard"
                                    >
                                        {listGame.map((item) => (
                                            <MenuItem key={item.id} value={item.value}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} py={1} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="Depth-of-Field">Depth of Field</InputLabel>
                                    <Select
                                        labelId="Depth-of-Field-label"
                                        id="Depth-of-Field"
                                        value={depthOfField}
                                        label="Depth of Field"
                                        onChange={handleChange_depthOfField}
                                        variant="standard"
                                    >
                                        {listDepthOfField.map((item) => (
                                            <MenuItem key={item.id} value={item.value}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item mt={1} xs={12} md={8}>
                                <TextField
                                    id="attempt-to-write-words"
                                    label="Text (attempt to write words, but it might not work)"
                                    variant="standard"
                                    fullWidth
                                    value={writtenWord}
                                    onChange={handleChange_writtenWord}
                                />
                            </Grid>
                            <Grid item mt={1} xs={12} md={4}>
                                <TextField
                                    id="attempt-to-write-words-weight"
                                    label="Weight"
                                    variant="standard"
                                    fullWidth
                                    type="number"
                                    value={writtenWordWeight}
                                    onChange={handleChange_writtenWordWeight}
                                />
                            </Grid>

                            <Grid item mt={2} xs={12} md={12}>
                                <Typography variant="h4" color="primary">
                                    Here we go!
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <TextField
                                    id="standard-multiline-static"
                                    label="Output (prompt)"
                                    multiline
                                    rows={4}
                                    variant="standard"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth
                                    value={mappingPromptTextResult()}
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Stack direction="row" mt={2} spacing={2}>
                                    <Button
                                        onClick={copyToClipboard}
                                        variant="outlined"
                                        startIcon={<ContentCopyIcon />}
                                        color="success"
                                    >
                                        Copy to Clipboard
                                    </Button>

                                    <Button
                                        onClick={clearPromptText}
                                        variant="outlined"
                                        startIcon={<BackspaceIcon />}
                                        color="error"
                                    >
                                        Clear
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </main>
        </>
    );
}
