export const listVersion = [
  {
    id: 1,
    name: "V4b New Style (newest algorithm with --style 4b)",
    value: " --style 4b",
  },
  {
    id: 2,
    name: "V4a Old Style (newest algorithm with --style 4a)",
    value: " --style 4a",
  },
  {
    id: 3,
    name: "V3 (artistic and creative)",
    value: " --v 3",
  },
  {
    id: 4,
    name: "V2 (old algorithm / more abstract)",
    value: " --v 2",
  },
  {
    id: 5,
    name: "V1 (vibe for textures / macro)",
    value: " --v 1",
  },
];

export const listAspectRatio = [
  {
    id: 1,
    name: "1:1 → Square, Instagram, default in MidJourney",
    value: "1:1",
  },
  {
    id: 2,
    name: "3:2 → Widest view - prints at 4x6, 8x12, 16x24, 20x30, 24x36, 30x40",
    value: "3:2",
  },
  { id: 3, name: "2:3 → (same as above, but vertical)", value: "2:3" },
];

export const listMedium = [
  { id: 2, name: "ink drawing", value: "ink drawing" },
  { id: 4, name: "▬▬▬▬▬ Art Movements", value: "▬▬▬▬▬ Art Movements" },
  {
    id: 5,
    name: "abstract expressionist art style",
    value: "abstract expressionist art style",
  },
  { id: 6, name: "art deco style", value: "art deco style" },
  { id: 7, name: "art nouveau style", value: "art nouveau style" },
  { id: 8, name: "avant-garde art style", value: "avant-garde art style" },
  { id: 9, name: "baroque art style", value: "baroque art style" },
  { id: 10, name: "bauhaus art style", value: "bauhaus art style" },
  { id: 11, name: "color field art style", value: "color field art style" },
  { id: 12, name: "conceptual art style", value: "conceptual art style" },
  {
    id: 13,
    name: "constructivism art style",
    value: "constructivism art style",
  },
  { id: 14, name: "cubism art style", value: "cubism art style" },
  { id: 15, name: "dada art style", value: "dada art style" },
  {
    id: 16,
    name: "expressionism art style",
    value: "expressionism art style",
  },
  { id: 17, name: "fauvism art style", value: "fauvism art style" },
  { id: 18, name: "fresco art style", value: "fresco art style" },
  { id: 19, name: "futurism art style", value: "futurism art style" },
  {
    id: 20,
    name: "harlem renaissance art style",
    value: "harlem renaissance art style",
  },
  {
    id: 21,
    name: "impressionism art style",
    value: "impressionism art style",
  },
  { id: 22, name: "installation art style", value: "installation art style" },
  { id: 23, name: "land art style", value: "land art style" },
  { id: 24, name: "minimalism art style", value: "minimalism art style" },
  {
    id: 25,
    name: "neo-impressionism art style",
    value: "neo-impressionism art style",
  },
  { id: 26, name: "op art style", value: "op art style" },
  { id: 27, name: "pop art style", value: "pop art style" },
  {
    id: 28,
    name: "post-impressionism art style",
    value: "post-impressionism art style",
  },
  { id: 29, name: "precisionism art style", value: "precisionism art style" },
  { id: 30, name: "psychedelic art style", value: "psychedelic art style" },
  { id: 31, name: "street art style", value: "street art style" },
  { id: 32, name: "suprematism art style", value: "suprematism art style" },
  { id: 33, name: "surrealism art style", value: "surrealism art style" },
  { id: 34, name: "symbolism art style", value: "symbolism art style" },
  { id: 35, name: "▬▬▬▬▬ Flat Output", value: "▬▬▬▬▬ Flat Output" },
  { id: 36, name: "3D rendering", value: "3D rendering" },
  { id: 37, name: "blueprint", value: "blueprint" },
  { id: 38, name: "collage", value: "collage" },
  { id: 39, name: "comic book", value: "comic book" },
  { id: 40, name: "digital art", value: "digital art" },
  { id: 41, name: "icon", value: "icon" },
  { id: 42, name: "IKEA guide", value: "IKEA guide" },
  { id: 43, name: "instruction manual", value: "instruction manual" },
  { id: 44, name: "magazine", value: "magazine" },
  { id: 45, name: "map", value: "map" },
  { id: 46, name: "mosaic", value: "mosaic" },
  { id: 47, name: "movie poster", value: "movie poster" },
  { id: 48, name: "newspaper", value: "newspaper" },
  { id: 49, name: "photograph", value: "photograph" },
  { id: 50, name: "photorealism", value: "photorealism" },
  { id: 51, name: "pixel art", value: "pixel art" },
  { id: 52, name: "schematic", value: "schematic" },
  { id: 53, name: "signage", value: "signage" },
  { id: 54, name: "stained glass", value: "stained glass" },
  { id: 55, name: "sticker", value: "sticker" },
  { id: 56, name: "tattoo", value: "tattoo" },
  { id: 57, name: "vector art", value: "vector art" },
  { id: 58, name: "woodblock print", value: "woodblock print" },
  {
    id: 59,
    name: "▬▬▬▬▬ Flat Output: Drawing",
    value: "▬▬▬▬▬ Flat Output: Drawing",
  },
  { id: 60, name: "anatomical drawing", value: "anatomical drawing" },
  { id: 61, name: "anime", value: "anime" },
  { id: 62, name: "ballpoint pen drawing", value: "ballpoint pen drawing" },
  { id: 63, name: "cartoon", value: "cartoon" },
  { id: 64, name: "chalk", value: "chalk" },
  { id: 65, name: "chalkboard drawing", value: "chalkboard drawing" },
  { id: 66, name: "charcoal drawing", value: "charcoal drawing" },
  { id: 67, name: "child's drawing", value: "child's drawing" },
  { id: 68, name: "collage", value: "collage" },
  { id: 69, name: "colored pencil drawing", value: "colored pencil drawing" },
  { id: 70, name: "coloring book", value: "coloring book" },
  { id: 71, name: "comics", value: "comics" },
  { id: 72, name: "crosshatch drawing", value: "crosshatch drawing" },
  { id: 73, name: "doodle", value: "doodle" },
  { id: 74, name: "graphic novel", value: "graphic novel" },
  { id: 75, name: "illuminated manuscript", value: "illuminated manuscript" },
  { id: 76, name: "india ink", value: "india ink" },
  { id: 77, name: "ink drawing", value: "ink drawing" },
  { id: 78, name: "marker drawing", value: "marker drawing" },
  { id: 79, name: "pastel", value: "pastel" },
  { id: 80, name: "pencil drawing", value: "pencil drawing" },
  { id: 81, name: "pointillism", value: "pointillism" },
  { id: 82, name: "stencil", value: "stencil" },
  { id: 83, name: "stipple", value: "stipple" },
  { id: 84, name: "storybook illustration", value: "storybook illustration" },
  { id: 85, name: "tarot card", value: "tarot card" },
  { id: 86, name: "trading card", value: "trading card" },
  { id: 87, name: "ukiyo-e", value: "ukiyo-e" },
  {
    id: 88,
    name: "▬▬▬▬▬ Flat Output: Painting",
    value: "▬▬▬▬▬ Flat Output: Painting",
  },
  { id: 89, name: "airbrush painting", value: "airbrush painting" },
  { id: 90, name: "cave painting", value: "cave painting" },
  { id: 91, name: "color field painting", value: "color field painting" },
  { id: 92, name: "glass painting", value: "glass painting" },
  { id: 93, name: "glitter painting", value: "glitter painting" },
  { id: 94, name: "graffiti painting", value: "graffiti painting" },
  { id: 95, name: "hydro-dipping painting", value: "hydro-dipping painting" },
  { id: 96, name: "light painting", value: "light painting" },
  { id: 97, name: "mural painting", value: "mural painting" },
  { id: 98, name: "painting", value: "painting" },
  { id: 99, name: "puffy paint painting", value: "puffy paint painting" },
  { id: 100, name: "scroll painting", value: "scroll painting" },
  { id: 101, name: "splatter painting", value: "splatter painting" },
  { id: 102, name: "spray painting", value: "spray painting" },
  { id: 103, name: "still-life painting", value: "still-life painting" },
  { id: 104, name: "street art painting", value: "street art painting" },
  { id: 105, name: "watercolor painting", value: "watercolor painting" },
  { id: 106, name: "wet paint painting", value: "wet paint painting" },
  {
    id: 107,
    name: "▬▬▬▬▬ Dimensional Output",
    value: "▬▬▬▬▬ Dimensional Output",
  },
  { id: 108, name: "architecture", value: "architecture" },
  { id: 109, name: "balloon modeling", value: "balloon modeling" },
  { id: 110, name: "bone china", value: "bone china" },
  { id: 111, name: "claymation", value: "claymation" },
  { id: 112, name: "cross-stitch", value: "cross-stitch" },
  { id: 113, name: "diorama", value: "diorama" },
  { id: 114, name: "enamel pin", value: "enamel pin" },
  { id: 115, name: "etching", value: "etching" },
  { id: 116, name: "ice sculpture", value: "ice sculpture" },
  { id: 117, name: "origami", value: "origami" },
  { id: 118, name: "paper model", value: "paper model" },
  { id: 119, name: "pottery", value: "pottery" },
  { id: 120, name: "relief carving", value: "relief carving" },
  { id: 121, name: "resin", value: "resin" },
  { id: 122, name: "rock art", value: "rock art" },
  { id: 123, name: "sculpture", value: "sculpture" },
  { id: 124, name: "stained glass", value: "stained glass" },
  { id: 125, name: "stone tablet", value: "stone tablet" },
  { id: 126, name: "string art", value: "string art" },
  { id: 127, name: "textile", value: "textile" },
  { id: 128, name: "wood carving", value: "wood carving" },
  { id: 129, name: "yarn knitting", value: "yarn knitting" },
  { id: 130, name: "▬▬▬▬▬ Photography", value: "▬▬▬▬▬ Photography" },
  { id: 131, name: "photograph", value: "photograph" },
  { id: 132, name: "35mm film photograph", value: "35mm film photograph" },
  { id: 133, name: "agfacolor photograph", value: "agfacolor photograph" },
  { id: 134, name: "ambrotype photograph", value: "ambrotype photograph" },
  { id: 135, name: "calotype photograph", value: "calotype photograph" },
  {
    id: 136,
    name: "collodion glass plate photograph",
    value: "collodion glass plate photograph",
  },
  { id: 137, name: "cyanotype photograph", value: "cyanotype photograph" },
  {
    id: 138,
    name: "daguerreotype photograph",
    value: "daguerreotype photograph",
  },
  { id: 139, name: "depth map photograph", value: "depth map photograph" },
  {
    id: 140,
    name: "disposable camera photograph",
    value: "disposable camera photograph",
  },
  {
    id: 141,
    name: "expired film photograph",
    value: "expired film photograph",
  },
  { id: 142, name: "expired polaroid", value: "expired polaroid" },
  {
    id: 143,
    name: "film negative photograph",
    value: "film negative photograph",
  },
  { id: 144, name: "glamor photography", value: "glamor photography" },
  { id: 145, name: "hyperspectral imaging", value: "hyperspectral imaging" },
  { id: 146, name: "infrared photography", value: "infrared photography" },
  { id: 147, name: "instax photograph", value: "instax photograph" },
  { id: 148, name: "knolling photograph", value: "knolling photograph" },
  { id: 149, name: "kodachrome photograph", value: "kodachrome photograph" },
  { id: 150, name: "lomography photograph", value: "lomography photograph" },
  {
    id: 151,
    name: "night vision photograph",
    value: "night vision photograph",
  },
  { id: 152, name: "paparazzi photograph", value: "paparazzi photograph" },
  { id: 153, name: "photogram photograph", value: "photogram photograph" },
  { id: 154, name: "pinhole photograph", value: "pinhole photograph" },
  { id: 155, name: "polaroid photograph", value: "polaroid photograph" },
  { id: 156, name: "portrait photograph", value: "portrait photograph" },
  { id: 157, name: "product photograph", value: "product photograph" },
  { id: 158, name: "sepia photograph", value: "sepia photograph" },
  { id: 159, name: "silhouette photograph", value: "silhouette photograph" },
  {
    id: 160,
    name: "tilt-shift lens photograph",
    value: "tilt-shift lens photograph",
  },
  { id: 161, name: "tintype photograph", value: "tintype photograph" },
  { id: 162, name: "trail cam photograph", value: "trail cam photograph" },
  {
    id: 163,
    name: "ultraviolet photograph",
    value: "ultraviolet photograph",
  },
  {
    id: 164,
    name: "vintage film photograph",
    value: "vintage film photograph",
  },
  { id: 165, name: "▬▬▬▬▬ Displays", value: "▬▬▬▬▬ Displays" },
  {
    id: 166,
    name: "1990s computer graphics",
    value: "1990s computer graphics",
  },
  { id: 167, name: "4k", value: "4k" },
  { id: 168, name: "ASCII", value: "ASCII" },
  { id: 169, name: "atari graphics", value: "atari graphics" },
  { id: 170, name: "CRT display", value: "CRT display" },
  { id: 171, name: "dot matrix display", value: "dot matrix display" },
  {
    id: 172,
    name: "electroluminescent display",
    value: "electroluminescent display",
  },
  { id: 173, name: "holograph", value: "holograph" },
  { id: 174, name: "LCD display", value: "LCD display" },
  { id: 175, name: "LED display", value: "LED display" },
  { id: 176, name: "pixel art", value: "pixel art" },
  { id: 177, name: "quantum dot display", value: "quantum dot display" },
  { id: 178, name: "VHS tape", value: "VHS tape" },
];

export const listCamera = [
  { id: 2, name: "▬▬▬▬▬ Small to Large", value: "▬▬▬▬▬ Small to Large" },
  {
    id: 3,
    name: "super-resolution microscopy",
    value: "super-resolution microscopy",
  },
  { id: 4, name: "microscopy", value: "microscopy" },
  { id: 5, name: "macro lens", value: "macro lens" },
  { id: 6, name: "pinhole lens", value: "pinhole lens" },
  { id: 7, name: "first person view", value: "first person view" },
  { id: 8, name: "wide angle lens", value: "wide angle lens" },
  { id: 9, name: "ultra-wide angle lens", value: "ultra-wide angle lens" },
  { id: 10, name: "fisheye lens", value: "fisheye lens" },
  { id: 11, name: "telephoto lens", value: "telephoto lens" },
  { id: 12, name: "panorama", value: "panorama" },
  { id: 13, name: "360 panorama", value: "360 panorama" },
  { id: 14, name: "tilt-shift lens", value: "tilt-shift lens" },
  { id: 15, name: "telescope view", value: "telescope view" },
  { id: 16, name: "drone view", value: "drone view" },
  { id: 17, name: "aerial", value: "aerial" },
  { id: 18, name: "from above", value: "from above" },
  { id: 19, name: "satellite imagery", value: "satellite imagery" },
  { id: 21, name: "blurry", value: "blurry" },
  { id: 22, name: "bokeh", value: "bokeh" },
  { id: 23, name: "ICM", value: "ICM" },
  { id: 24, name: "long exposure", value: "long exposure" },
  { id: 25, name: "motion blur", value: "motion blur" },
  { id: 26, name: "soft focus", value: "soft focus" },
  { id: 28, name: "8mm lens", value: "8mm lens" },
  { id: 29, name: "35mm lens", value: "35mm lens" },
  { id: 30, name: "50mm lens", value: "50mm lens" },
  { id: 31, name: "80mm lens", value: "80mm lens" },
  { id: 32, name: "200mm lens", value: "200mm lens" },
  { id: 33, name: "500mm lens", value: "500mm lens" },
];

export const listLighting = [
  { id: 2, name: "lantern", value: "lantern" },
  { id: 4, name: "▬▬▬▬▬ Styles", value: "▬▬▬▬▬ Styles" },
  { id: 5, name: "bright lighting", value: "bright lighting" },
  { id: 6, name: "campfire lighting", value: "campfire lighting" },
  { id: 7, name: "candlelight", value: "candlelight" },
  { id: 8, name: "cinematic lighting", value: "cinematic lighting" },
  { id: 9, name: "colorful lighting", value: "colorful lighting" },
  { id: 10, name: "contre-jour lighting", value: "contre-jour lighting" },
  { id: 11, name: "dark lighting", value: "dark lighting" },
  { id: 12, name: "dramatic lighting", value: "dramatic lighting" },
  { id: 13, name: "early morning lighting", value: "early morning lighting" },
  { id: 14, name: "film noir lighting", value: "film noir lighting" },
  { id: 15, name: "golden hour sunlight", value: "golden hour sunlight" },
  { id: 16, name: "hard lighting", value: "hard lighting" },
  { id: 17, name: "moody lighting", value: "moody lighting" },
  { id: 18, name: "night lighting", value: "night lighting" },
  { id: 19, name: "realistic lighting", value: "realistic lighting" },
  { id: 20, name: "soft lighting", value: "soft lighting" },
  { id: 21, name: "studio lighting", value: "studio lighting" },
  { id: 22, name: "sunset lighting", value: "sunset lighting" },
  { id: 23, name: "volumetric lighting", value: "volumetric lighting" },
  { id: 24, name: "▬▬▬▬▬ Types", value: "▬▬▬▬▬ Types" },
  { id: 25, name: "backlight", value: "backlight" },
  { id: 26, name: "bioluminescence", value: "bioluminescence" },
  { id: 27, name: "black light bulb", value: "black light bulb" },
  { id: 28, name: "candle light", value: "candle light" },
  { id: 29, name: "christmas lights", value: "christmas lights" },
  { id: 30, name: "crepuscular rays", value: "crepuscular rays" },
  { id: 31, name: "daylight", value: "daylight" },
  { id: 32, name: "edison bulb", value: "edison bulb" },
  { id: 33, name: "fire light", value: "fire light" },
  { id: 34, name: "floodlight", value: "floodlight" },
  { id: 35, name: "fluorescent bulb", value: "fluorescent bulb" },
  { id: 36, name: "glow in the dark light", value: "glow in the dark light" },
  { id: 37, name: "glow stick", value: "glow stick" },
  { id: 38, name: "glowing light", value: "glowing light" },
  { id: 39, name: "halfrear lighting", value: "halfrear lighting" },
  { id: 40, name: "infrared light", value: "infrared light" },
  { id: 41, name: "lantern light", value: "lantern light" },
  { id: 42, name: "laser light", value: "laser light" },
  { id: 43, name: "LED lights", value: "LED lights" },
  { id: 44, name: "lens flare", value: "lens flare" },
  { id: 45, name: "light rays", value: "light rays" },
  { id: 46, name: "natural lighting", value: "natural lighting" },
  { id: 47, name: "neon bulb", value: "neon bulb" },
  { id: 48, name: "nixie tube bulb", value: "nixie tube bulb" },
  { id: 49, name: "plasma globe", value: "plasma globe" },
  { id: 50, name: "silhouette lighting", value: "silhouette lighting" },
  { id: 51, name: "spotlight", value: "spotlight" },
  { id: 52, name: "sunlight", value: "sunlight" },
  { id: 53, name: "vacuum tube bulb", value: "vacuum tube bulb" },
  { id: 54, name: "x-ray", value: "x-ray" },
];

export const listColor = [
  { id: 2, name: "rainbow color", value: "rainbow color" },
  { id: 4, name: "▬▬▬▬▬ Grayscale", value: "▬▬▬▬▬ Grayscale" },
  { id: 5, name: "white", value: "white" },
  { id: 6, name: "black", value: "black" },
  { id: 7, name: "light gray", value: "light gray" },
  { id: 8, name: "dark gray", value: "dark gray" },
  { id: 9, name: "▬▬▬▬▬ Colors", value: "▬▬▬▬▬ Colors" },
  { id: 10, name: "maroon", value: "maroon" },
  { id: 11, name: "red", value: "red" },
  { id: 12, name: "orange", value: "orange" },
  { id: 13, name: "yellow", value: "yellow" },
  { id: 14, name: "lime", value: "lime" },
  { id: 15, name: "green", value: "green" },
  { id: 16, name: "cyan", value: "cyan" },
  { id: 17, name: "teal", value: "teal" },
  { id: 18, name: "blue", value: "blue" },
  { id: 19, name: "indigo", value: "indigo" },
  { id: 20, name: "purple", value: "purple" },
  { id: 21, name: "violet", value: "violet" },
  { id: 22, name: "brown", value: "brown" },
  { id: 23, name: "▬▬▬▬▬ Colors (dark)", value: "▬▬▬▬▬ Colors (dark)" },
  { id: 24, name: "dark-maroon", value: "dark-maroon" },
  { id: 25, name: "dark-red", value: "dark-red" },
  { id: 26, name: "dark-orange", value: "dark-orange" },
  { id: 27, name: "dark-yellow", value: "dark-yellow" },
  { id: 28, name: "dark-lime", value: "dark-lime" },
  { id: 29, name: "dark-green", value: "dark-green" },
  { id: 30, name: "dark-cyan", value: "dark-cyan" },
  { id: 31, name: "dark-teal", value: "dark-teal" },
  { id: 32, name: "dark-blue", value: "dark-blue" },
  { id: 33, name: "dark-indigo", value: "dark-indigo" },
  { id: 34, name: "dark-purple", value: "dark-purple" },
  { id: 35, name: "dark-violet", value: "dark-violet" },
  { id: 36, name: "dark-brown", value: "dark-brown" },
  { id: 37, name: "▬▬▬▬▬ Colors (light)", value: "▬▬▬▬▬ Colors (light)" },
  { id: 38, name: "light-maroon", value: "light-maroon" },
  { id: 39, name: "light-red", value: "light-red" },
  { id: 40, name: "light-orange", value: "light-orange" },
  { id: 41, name: "light-yellow", value: "light-yellow" },
  { id: 42, name: "light-lime", value: "light-lime" },
  { id: 43, name: "light-green", value: "light-green" },
  { id: 44, name: "light-cyan", value: "light-cyan" },
  { id: 45, name: "light-teal", value: "light-teal" },
  { id: 46, name: "light-blue", value: "light-blue" },
  { id: 47, name: "light-indigo", value: "light-indigo" },
  { id: 48, name: "light-purple", value: "light-purple" },
  { id: 49, name: "light-violet", value: "light-violet" },
  { id: 50, name: "light-brown", value: "light-brown" },
  { id: 51, name: "▬▬▬▬▬ Styles", value: "▬▬▬▬▬ Styles" },
  { id: 52, name: "black and white", value: "black and white" },
  { id: 53, name: "complementary color", value: "complementary color" },
  { id: 54, name: "cool color", value: "cool color" },
  { id: 55, name: "dark color", value: "dark color" },
  { id: 56, name: "dichromatism color", value: "dichromatism color" },
  { id: 57, name: "faded color", value: "faded color" },
  { id: 58, name: "high contrast", value: "high contrast" },
  { id: 59, name: "inverted color", value: "inverted color" },
  { id: 60, name: "light color", value: "light color" },
  { id: 61, name: "low contrast", value: "low contrast" },
  { id: 62, name: "monochromatic color", value: "monochromatic color" },
  { id: 63, name: "muted color", value: "muted color" },
  { id: 64, name: "neon color", value: "neon color" },
  { id: 65, name: "pantone color", value: "pantone color" },
  { id: 66, name: "pastel color", value: "pastel color" },
  { id: 67, name: "rainbow color", value: "rainbow color" },
  { id: 68, name: "saturated color", value: "saturated color" },
  { id: 69, name: "sepia color", value: "sepia color" },
  { id: 70, name: "spectral solar", value: "spectral solar" },
  { id: 71, name: "vibrant color", value: "vibrant color" },
  { id: 72, name: "vivid color", value: "vivid color" },
  { id: 73, name: "warm color", value: "warm color" },
  { id: 74, name: "▬▬▬▬▬ Displays", value: "▬▬▬▬▬ Displays" },
  { id: 75, name: "RGB", value: "RGB" },
  { id: 76, name: "CMYK", value: "CMYK" },
  { id: 77, name: "1-bit grayscale", value: "1-bit grayscale" },
  { id: 78, name: "4-bit grayscale", value: "4-bit grayscale" },
  { id: 79, name: "3-bit color", value: "3-bit color" },
  { id: 80, name: "6-bit color", value: "6-bit color" },
  { id: 81, name: "technicolor", value: "technicolor" },
  { id: 82, name: "▬▬▬▬▬ Seasonal Colors", value: "▬▬▬▬▬ Seasonal Colors" },
  { id: 83, name: "spring colors", value: "spring colors" },
  { id: 84, name: "summer colors", value: "summer colors" },
  { id: 85, name: "autumn colors", value: "autumn colors" },
  { id: 86, name: "winter colors", value: "winter colors" },
  { id: 87, name: "▬▬▬▬▬ Color Mix Styles", value: "▬▬▬▬▬ Color Mix Styles" },
  { id: 88, name: "camouflage color", value: "camouflage color" },
  { id: 89, name: "confetti color", value: "confetti color" },
  {
    id: 90,
    name: "diffraction pattern color",
    value: "diffraction pattern color",
  },
  { id: 91, name: "disco color", value: "disco color" },
  { id: 92, name: "funky color", value: "funky color" },
  { id: 93, name: "gradient color", value: "gradient color" },
  { id: 94, name: "halftone color", value: "halftone color" },
  { id: 95, name: "polka dot color", value: "polka dot color" },
  { id: 96, name: "pop-art color", value: "pop-art color" },
  { id: 97, name: "purity color", value: "purity color" },
  { id: 98, name: "spectrum color", value: "spectrum color" },
  { id: 99, name: "synesthesia color", value: "synesthesia color" },
  { id: 100, name: "tie-dye color", value: "tie-dye color" },
  { id: 101, name: "▬▬▬▬▬ Photography", value: "▬▬▬▬▬ Photography" },
  { id: 102, name: "35mm film color", value: "35mm film color" },
  { id: 103, name: "agfacolor color", value: "agfacolor color" },
  { id: 104, name: "ambrotype color", value: "ambrotype color" },
  { id: 105, name: "bokeh color", value: "bokeh color" },
  { id: 106, name: "calotype color", value: "calotype color" },
  {
    id: 107,
    name: "chromatic aberration color",
    value: "chromatic aberration color",
  },
  {
    id: 108,
    name: "collodion glass plate color",
    value: "collodion glass plate color",
  },
  { id: 109, name: "cyanotype color", value: "cyanotype color" },
  { id: 110, name: "daguerreotype color", value: "daguerreotype color" },
  { id: 111, name: "depth map color", value: "depth map color" },
  { id: 112, name: "expired film color", value: "expired film color" },
  { id: 113, name: "expired polaroid", value: "expired polaroid" },
  { id: 114, name: "film negative color", value: "film negative color" },
  { id: 115, name: "fujifilm digital", value: "fujifilm digital" },
  {
    id: 116,
    name: "hyperspectral imaging color",
    value: "hyperspectral imaging color",
  },
  { id: 117, name: "instax color", value: "instax color" },
  { id: 118, name: "kodachrome color", value: "kodachrome color" },
  {
    id: 119,
    name: "kodak gold 200 film color",
    value: "kodak gold 200 film color",
  },
  { id: 120, name: "lomography color", value: "lomography color" },
  { id: 121, name: "night vision color", value: "night vision color" },
  { id: 122, name: "photogram color", value: "photogram color" },
  { id: 123, name: "polaroid color", value: "polaroid color" },
  {
    id: 124,
    name: "sepia photograph color",
    value: "sepia photograph color",
  },
  { id: 125, name: "tintype color", value: "tintype color" },
  { id: 126, name: "tri-x 400 tx color", value: "tri-x 400 tx color" },
  { id: 127, name: "ultraviolet color", value: "ultraviolet color" },
  { id: 128, name: "vignette color", value: "vignette color" },
  { id: 129, name: "vintage film color", value: "vintage film color" },
];

export const listDescriptor = [
  { id: 2, name: "rorschach", value: "rorschach" },
  { id: 4, name: "▬▬▬▬▬ Realism", value: "▬▬▬▬▬ Realism" },
  { id: 5, name: "classical realism", value: "classical realism" },
  { id: 6, name: "contemporary realism", value: "contemporary realism" },
  { id: 7, name: "fantastic realism", value: "fantastic realism" },
  { id: 8, name: "hyper realistic", value: "hyper realistic" },
  { id: 9, name: "magic realism", value: "magic realism" },
  { id: 10, name: "photorealistic", value: "photorealistic" },
  { id: 11, name: "realistic", value: "realistic" },
  { id: 12, name: "▬▬▬▬▬ Post-Realism", value: "▬▬▬▬▬ Post-Realism" },
  { id: 13, name: "abstract", value: "abstract" },
  { id: 14, name: "acidwave", value: "acidwave" },
  { id: 15, name: "aura", value: "aura" },
  { id: 16, name: "brutalism", value: "brutalism" },
  { id: 17, name: "chakra", value: "chakra" },
  { id: 18, name: "cubism", value: "cubism" },
  { id: 19, name: "cyberpunk", value: "cyberpunk" },
  { id: 20, name: "cyborgism", value: "cyborgism" },
  { id: 21, name: "dark fantasy", value: "dark fantasy" },
  { id: 22, name: "dreamlike", value: "dreamlike" },
  { id: 23, name: "forestpunk", value: "forestpunk" },
  { id: 24, name: "fractalpunk", value: "fractalpunk" },
  { id: 25, name: "futurism", value: "futurism" },
  { id: 26, name: "futuristic", value: "futuristic" },
  { id: 27, name: "glo-fi", value: "glo-fi" },
  { id: 28, name: "nanopunk", value: "nanopunk" },
  { id: 29, name: "psychedelic", value: "psychedelic" },
  { id: 30, name: "psychedelica", value: "psychedelica" },
  { id: 31, name: "rainbowcore", value: "rainbowcore" },
  { id: 32, name: "sci-fi", value: "sci-fi" },
  { id: 33, name: "shpongle", value: "shpongle" },
  { id: 34, name: "sparklecore", value: "sparklecore" },
  { id: 35, name: "steampunk", value: "steampunk" },
  { id: 36, name: "surreal", value: "surreal" },
  { id: 37, name: "synthwave", value: "synthwave" },
  { id: 38, name: "vaporwave", value: "vaporwave" },
  {
    id: 39,
    name: "▬▬▬▬▬ Details: Simple, Basic",
    value: "▬▬▬▬▬ Details: Simple, Basic",
  },
  { id: 40, name: "depth of field", value: "depth of field" },
  { id: 41, name: "flat", value: "flat" },
  { id: 42, name: "flat shading", value: "flat shading" },
  { id: 43, name: "minimalist", value: "minimalist" },
  { id: 44, name: "simple", value: "simple" },
  { id: 45, name: "▬▬▬▬▬ Details: Complex", value: "▬▬▬▬▬ Details: Complex" },
  { id: 46, name: "asymmetrical", value: "asymmetrical" },
  { id: 47, name: "bling", value: "bling" },
  { id: 48, name: "camouflage", value: "camouflage" },
  { id: 49, name: "chaotic", value: "chaotic" },
  { id: 50, name: "chart", value: "chart" },
  { id: 51, name: "complex", value: "complex" },
  { id: 52, name: "deep dream", value: "deep dream" },
  { id: 53, name: "detailed", value: "detailed" },
  { id: 54, name: "diagram", value: "diagram" },
  { id: 55, name: "dreamcore", value: "dreamcore" },
  { id: 56, name: "fractal", value: "fractal" },
  { id: 57, name: "glassmorphism", value: "glassmorphism" },
  { id: 58, name: "gliophorus-psittacinus", value: "gliophorus-psittacinus" },
  { id: 59, name: "graph", value: "graph" },
  { id: 60, name: "grid", value: "grid" },
  { id: 61, name: "high definition", value: "high definition" },
  { id: 62, name: "hyper realistic", value: "hyper realistic" },
  { id: 63, name: "hyperbolic", value: "hyperbolic" },
  { id: 64, name: "insanely detailed", value: "insanely detailed" },
  { id: 65, name: "intricate", value: "intricate" },
  { id: 66, name: "kaleidoscope", value: "kaleidoscope" },
  { id: 67, name: "knotted", value: "knotted" },
  { id: 68, name: "luxury", value: "luxury" },
  { id: 69, name: "mandala", value: "mandala" },
  { id: 70, name: "mandelbrot", value: "mandelbrot" },
  { id: 71, name: "mandelbulb", value: "mandelbulb" },
  { id: 72, name: "maximum detail", value: "maximum detail" },
  { id: 73, name: "micro details", value: "micro details" },
  { id: 74, name: "molecular", value: "molecular" },
  { id: 75, name: "newton fractal", value: "newton fractal" },
  { id: 76, name: "ornate", value: "ornate" },
  { id: 77, name: "pattern", value: "pattern" },
  { id: 78, name: "rorschach", value: "rorschach" },
  { id: 79, name: "sacred geometry", value: "sacred geometry" },
  { id: 80, name: "seamless", value: "seamless" },
  { id: 81, name: "spirograph", value: "spirograph" },
  { id: 82, name: "symmetrical", value: "symmetrical" },
  { id: 83, name: "trending on ArtStation", value: "trending on ArtStation" },
  { id: 84, name: "Flickr", value: "Flickr" },
  { id: 85, name: "DeviantArt", value: "DeviantArt" },
  {
    id: 86,
    name: "▬▬▬▬▬ Rendering: Styles",
    value: "▬▬▬▬▬ Rendering: Styles",
  },
  { id: 87, name: "3D Render", value: "3D Render" },
  { id: 88, name: "atari", value: "atari" },
  { id: 89, name: "blender render", value: "blender render" },
  { id: 90, name: "cinema 4D", value: "cinema 4D" },
  { id: 91, name: "cryengine", value: "cryengine" },
  { id: 92, name: "houdini-render", value: "houdini-render" },
  { id: 93, name: "icon", value: "icon" },
  { id: 94, name: "low poly", value: "low poly" },
  { id: 95, name: "microsoft paint", value: "microsoft paint" },
  { id: 96, name: "nintendo", value: "nintendo" },
  { id: 97, name: "octane", value: "octane" },
  { id: 98, name: "openGL", value: "openGL" },
  { id: 99, name: "photoshop", value: "photoshop" },
  { id: 100, name: "raylectron", value: "raylectron" },
  { id: 101, name: "sketchup", value: "sketchup" },
  { id: 102, name: "unreal engine", value: "unreal engine" },
  { id: 103, name: "vector graphic", value: "vector graphic" },
  { id: 104, name: "zbrush", value: "zbrush" },
  {
    id: 105,
    name: "▬▬▬▬▬ Rendering: Reflections",
    value: "▬▬▬▬▬ Rendering: Reflections",
  },
  {
    id: 106,
    name: "ray tracing reflections",
    value: "ray tracing reflections",
  },
  { id: 107, name: "lumen reflections", value: "lumen reflections" },
  {
    id: 108,
    name: "screen space reflections",
    value: "screen space reflections",
  },
  { id: 109, name: "diffraction grating", value: "diffraction grating" },
  {
    id: 110,
    name: "▬▬▬▬▬ Rendering: Properties",
    value: "▬▬▬▬▬ Rendering: Properties",
  },
  { id: 111, name: "transparent", value: "transparent" },
  { id: 112, name: "textured", value: "textured" },
  { id: 113, name: "rough", value: "rough" },
  { id: 114, name: "matte", value: "matte" },
  { id: 115, name: "▬▬▬▬▬ Time", value: "▬▬▬▬▬ Time" },
  { id: 116, name: "1930s style", value: "1930s style" },
  { id: 117, name: "1950s style", value: "1950s style" },
  { id: 118, name: "1960s style", value: "1960s style" },
  { id: 119, name: "1970s style", value: "1970s style" },
  { id: 120, name: "1980s style", value: "1980s style" },
  { id: 121, name: "1990s style", value: "1990s style" },
  { id: 122, name: "Stone Age", value: "Stone Age" },
  { id: 123, name: "Bronze Age", value: "Bronze Age" },
  { id: 124, name: "Iron Age", value: "Iron Age" },
  { id: 125, name: "Ancient Greece", value: "Ancient Greece" },
  { id: 126, name: "Ancient Rome", value: "Ancient Rome" },
  { id: 127, name: "Persian Empire", value: "Persian Empire" },
  { id: 128, name: "Byzantine Empire", value: "Byzantine Empire" },
  { id: 129, name: "Renaissance Humanism", value: "Renaissance Humanism" },
  {
    id: 130,
    name: "Protestant Reformation",
    value: "Protestant Reformation",
  },
  { id: 131, name: "European Renaissance", value: "European Renaissance" },
  { id: 132, name: "Enlightenment", value: "Enlightenment" },
  { id: 133, name: "Industrial Revolution", value: "Industrial Revolution" },
  { id: 134, name: "Age of Imperialism", value: "Age of Imperialism" },
  { id: 135, name: "Victorian Era", value: "Victorian Era" },
  { id: 136, name: "World War I", value: "World War I" },
  { id: 137, name: "Great Depression", value: "Great Depression" },
  { id: 138, name: "World War II", value: "World War II" },
  { id: 139, name: "antique", value: "antique" },
  { id: 140, name: "medieval", value: "medieval" },
  { id: 141, name: "modern", value: "modern" },
  { id: 142, name: "retro", value: "retro" },
  { id: 143, name: "victorian", value: "victorian" },
  { id: 144, name: "vintage", value: "vintage" },
  { id: 145, name: "▬▬▬▬▬ Emotions Plus", value: "▬▬▬▬▬ Emotions Plus" },
  { id: 146, name: "angelic", value: "angelic" },
  { id: 147, name: "angry", value: "angry" },
  { id: 148, name: "annoyed", value: "annoyed" },
  { id: 149, name: "anxious", value: "anxious" },
  { id: 150, name: "aversion", value: "aversion" },
  { id: 151, name: "bitter", value: "bitter" },
  { id: 152, name: "creepy", value: "creepy" },
  { id: 153, name: "demonic", value: "demonic" },
  { id: 154, name: "depressing", value: "depressing" },
  { id: 155, name: "​dislike", value: "​dislike" },
  { id: 156, name: "dreamy", value: "dreamy" },
  { id: 157, name: "dystopian", value: "dystopian" },
  { id: 158, name: "ecstasy", value: "ecstasy" },
  { id: 159, name: "elegant", value: "elegant" },
  { id: 160, name: "elite", value: "elite" },
  { id: 161, name: "evil", value: "evil" },
  { id: 162, name: "excitement", value: "excitement" },
  { id: 163, name: "fashion", value: "fashion" },
  { id: 164, name: "frustrated", value: "frustrated" },
  { id: 165, name: "gloomy", value: "gloomy" },
  { id: 166, name: "grateful dead", value: "grateful dead" },
  { id: 167, name: "happy", value: "happy" },
  { id: 168, name: "hopeless", value: "hopeless" },
  { id: 169, name: "horrified", value: "horrified" },
  { id: 170, name: "horror", value: "horror" },
  { id: 171, name: "infuriated", value: "infuriated" },
  { id: 172, name: "insulted", value: "insulted" },
  { id: 173, name: "lonely", value: "lonely" },
  { id: 174, name: "luxury", value: "luxury" },
  { id: 175, name: "mad", value: "mad" },
  { id: 176, name: "mellow", value: "mellow" },
  { id: 177, name: "miserable", value: "miserable" },
  { id: 178, name: "moody", value: "moody" },
  { id: 179, name: "nauseated", value: "nauseated" },
  { id: 180, name: "nervous", value: "nervous" },
  { id: 181, name: "offended", value: "offended" },
  { id: 182, name: "ominous", value: "ominous" },
  { id: 183, name: "panicked", value: "panicked" },
  { id: 184, name: "revulsion", value: "revulsion" },
  { id: 185, name: "sad", value: "sad" },
  { id: 186, name: "satanic", value: "satanic" },
  { id: 187, name: "scared", value: "scared" },
  { id: 188, name: "sleepy", value: "sleepy" },
  { id: 189, name: "stressed", value: "stressed" },
  { id: 190, name: "tired", value: "tired" },
  { id: 191, name: "vengeful", value: "vengeful" },
  { id: 192, name: "whimsical", value: "whimsical" },
  { id: 193, name: "worried", value: "worried" },
  { id: 194, name: "▬▬▬▬▬ Seasons", value: "▬▬▬▬▬ Seasons" },
  { id: 195, name: "spring", value: "spring" },
  { id: 196, name: "summer", value: "summer" },
  { id: 197, name: "autumn", value: "autumn" },
  { id: 198, name: "winter", value: "winter" },
  { id: 199, name: "▬▬▬▬▬ Holidays", value: "▬▬▬▬▬ Holidays" },
  { id: 200, name: "Bodhi Day", value: "Bodhi Day" },
  { id: 201, name: "Chinese New Year", value: "Chinese New Year" },
  { id: 202, name: "Christmas", value: "Christmas" },
  { id: 203, name: "Cinco De Mayo", value: "Cinco De Mayo" },
  { id: 204, name: "Diwali", value: "Diwali" },
  { id: 205, name: "Easter", value: "Easter" },
  { id: 206, name: "Eid al-Fitr", value: "Eid al-Fitr" },
  { id: 207, name: "Halloween", value: "Halloween" },
  { id: 208, name: "Hanukkah", value: "Hanukkah" },
  { id: 209, name: "Independence Day", value: "Independence Day" },
  { id: 210, name: "New Year", value: "New Year" },
  { id: 211, name: "Ramadan", value: "Ramadan" },
  { id: 212, name: "St. Patrick’s Day", value: "St. Patrick’s Day" },
  { id: 213, name: "Thanksgiving", value: "Thanksgiving" },
  { id: 214, name: "Valentine’s Day", value: "Valentine’s Day" },
  { id: 215, name: "▬▬▬▬▬ Elements", value: "▬▬▬▬▬ Elements" },
  { id: 216, name: "ice", value: "ice" },
  { id: 217, name: "fire", value: "fire" },
  { id: 218, name: "liquid", value: "liquid" },
  { id: 219, name: "fog", value: "fog" },
  { id: 220, name: "wind", value: "wind" },
  { id: 221, name: "storm", value: "storm" },
  { id: 222, name: "flood", value: "flood" },
  { id: 223, name: "rain", value: "rain" },
  { id: 224, name: "organic", value: "organic" },
  { id: 225, name: "plasma", value: "plasma" },
  { id: 226, name: "▬▬▬▬▬ Shapes", value: "▬▬▬▬▬ Shapes" },
  { id: 227, name: "circle shape", value: "circle shape" },
  { id: 228, name: "semi-circle shape", value: "semi-circle shape" },
  { id: 229, name: "oval shape", value: "oval shape" },
  { id: 230, name: "square shape", value: "square shape" },
  { id: 231, name: "triangle shape", value: "triangle shape" },
  { id: 232, name: "rectangle shape", value: "rectangle shape" },
  { id: 233, name: "polygonal shape", value: "polygonal shape" },
  { id: 234, name: "cube shape", value: "cube shape" },
  { id: 235, name: "sphere shape", value: "sphere shape" },
  { id: 236, name: "cone shape", value: "cone shape" },
  { id: 237, name: "parallelogram shape", value: "parallelogram shape" },
  { id: 238, name: "rhombus shape", value: "rhombus shape" },
  { id: 239, name: "trapezoid shape", value: "trapezoid shape" },
  { id: 240, name: "kite shape", value: "kite shape" },
  { id: 241, name: "pentagon shape", value: "pentagon shape" },
  { id: 242, name: "hexagon shape", value: "hexagon shape" },
  { id: 243, name: "rubik cube shape", value: "rubik cube shape" },
  { id: 244, name: "organic shape", value: "organic shape" },
  { id: 245, name: "▬▬▬▬▬ Backgrounds", value: "▬▬▬▬▬ Backgrounds" },
  { id: 246, name: "white background", value: "white background" },
  { id: 247, name: "black background", value: "black background" },
  { id: 248, name: "flat background", value: "flat background" },
  { id: 249, name: "textured background", value: "textured background" },
  { id: 250, name: "hazy background", value: "hazy background" },
  { id: 251, name: "▬▬▬▬▬ Bodies", value: "▬▬▬▬▬ Bodies" },
  { id: 252, name: "attractive", value: "attractive" },
  { id: 253, name: "baby", value: "baby" },
  { id: 254, name: "beautiful", value: "beautiful" },
  { id: 255, name: "child", value: "child" },
  { id: 256, name: "cute", value: "cute" },
  { id: 257, name: "fat", value: "fat" },
  { id: 258, name: "female", value: "female" },
  { id: 259, name: "full body pose", value: "full body pose" },
  { id: 260, name: "handsome", value: "handsome" },
  { id: 261, name: "male", value: "male" },
  { id: 262, name: "messy", value: "messy" },
  { id: 263, name: "old", value: "old" },
  { id: 264, name: "short", value: "short" },
  { id: 265, name: "skinny", value: "skinny" },
  { id: 266, name: "tall", value: "tall" },
  { id: 267, name: "ugly", value: "ugly" },
  { id: 268, name: "young", value: "young" },
];

export const listArtist = [
  { id: 3, name: "Salvador Dalí", value: "Salvador Dalí" },
  { id: 5, name: "▬▬▬▬▬ Art Movements", value: "▬▬▬▬▬ Art Movements" },
  {
    id: 6,
    name: "▬▬▬▬▬ Abstract Expressionism (1940-1950, abstract, dripping, spilling)",
    value:
      "▬▬▬▬▬ Abstract Expressionism (1940-1950, abstract, dripping, spilling)",
  },
  { id: 7, name: "Ad Reinhardt", value: "Ad Reinhardt" },
  { id: 8, name: "Barnett Newman", value: "Barnett Newman" },
  { id: 9, name: "Clyfford Still", value: "Clyfford Still" },
  { id: 10, name: "Cy Twombly", value: "Cy Twombly" },
  { id: 11, name: "Franz Kline", value: "Franz Kline" },
  { id: 12, name: "Helen Frankenthaler", value: "Helen Frankenthaler" },
  { id: 13, name: "Jackson Pollock", value: "Jackson Pollock" },
  { id: 14, name: "Lee Krasner", value: "Lee Krasner" },
  { id: 15, name: "Mark Rothko", value: "Mark Rothko" },
  { id: 16, name: "Robert Motherwell", value: "Robert Motherwell" },
  { id: 17, name: "Willem de Kooning", value: "Willem de Kooning" },
  {
    id: 18,
    name: "▬▬▬▬▬ Art Deco (1920-1940, Europe and USA, craftsmanship, design, color, text)",
    value:
      "▬▬▬▬▬ Art Deco (1920-1940, Europe and USA, craftsmanship, design, color, text)",
  },
  { id: 19, name: "Eugene Grasset", value: "Eugene Grasset" },
  { id: 20, name: "Hector Guimard", value: "Hector Guimard" },
  { id: 21, name: "Jean Gabriel Domergue", value: "Jean Gabriel Domergue" },
  { id: 22, name: "Paul Poiret", value: "Paul Poiret" },
  { id: 23, name: "Raoul Dufy", value: "Raoul Dufy" },
  { id: 24, name: "Sonia Delaunay", value: "Sonia Delaunay" },
  { id: 25, name: "Tamara de Lempicka", value: "Tamara de Lempicka" },
  {
    id: 26,
    name: "▬▬▬▬▬ Art Nouveau (1880-1910, asymmetrical and organic, bold design)",
    value:
      "▬▬▬▬▬ Art Nouveau (1880-1910, asymmetrical and organic, bold design)",
  },
  { id: 27, name: "Alphonse Mucha", value: "Alphonse Mucha" },
  { id: 28, name: "Antoni Gaudí", value: "Antoni Gaudí" },
  { id: 29, name: "Aubrey Beardsley", value: "Aubrey Beardsley" },
  { id: 30, name: "Eugene Grasset", value: "Eugene Grasset" },
  { id: 31, name: "Gustav Klimt", value: "Gustav Klimt" },
  {
    id: 32,
    name: "Henri de Toulouse-Lautrec",
    value: "Henri de Toulouse-Lautrec",
  },
  { id: 33, name: "Jan Toorop", value: "Jan Toorop" },
  { id: 34, name: "Louis Comfort Tiffany", value: "Louis Comfort Tiffany" },
  { id: 35, name: "Victor Horta", value: "Victor Horta" },
  {
    id: 36,
    name: "▬▬▬▬▬ Avant-Garde (1910-1980, breaking barriers, new ideas, new mediums)",
    value:
      "▬▬▬▬▬ Avant-Garde (1910-1980, breaking barriers, new ideas, new mediums)",
  },
  { id: 37, name: "Kazimir Malevich", value: "Kazimir Malevich" },
  { id: 38, name: "Paul Klee", value: "Paul Klee" },
  { id: 39, name: "Pablo Picasso", value: "Pablo Picasso" },
  { id: 40, name: "Piero Manzoni", value: "Piero Manzoni" },
  { id: 41, name: "Robert Smithson", value: "Robert Smithson" },
  { id: 42, name: "Joseph Beuys", value: "Joseph Beuys" },
  { id: 43, name: "Jean-Michel Basquiat", value: "Jean-Michel Basquiat" },
  { id: 44, name: "Marina Abramovic", value: "Marina Abramovic" },
  {
    id: 45,
    name: "▬▬▬▬▬ Baroque (1590-1740, dramatic, motion, action)",
    value: "▬▬▬▬▬ Baroque (1590-1740, dramatic, motion, action)",
  },
  { id: 46, name: "Annibale Carracci", value: "Annibale Carracci" },
  {
    id: 47,
    name: "Domenikos Theotokopoulos",
    value: "Domenikos Theotokopoulos",
  },
  { id: 48, name: "Francesco Borromini", value: "Francesco Borromini" },
  { id: 49, name: "Gian Lorenzo Bernini", value: "Gian Lorenzo Bernini" },
  {
    id: 50,
    name: "Johannes Vermeer van Delft",
    value: "Johannes Vermeer van Delft",
  },
  {
    id: 51,
    name: "Michelangelo Merisi da Caravaggio",
    value: "Michelangelo Merisi da Caravaggio",
  },
  { id: 52, name: "Peter Paul Rubens", value: "Peter Paul Rubens" },
  { id: 53, name: "Rembrandt van Rijn", value: "Rembrandt van Rijn" },
  {
    id: 54,
    name: "▬▬▬▬▬ Bauhaus (1919-1933, German, art and architecture)",
    value: "▬▬▬▬▬ Bauhaus (1919-1933, German, art and architecture)",
  },
  { id: 55, name: "Walter Gropius", value: "Walter Gropius" },
  {
    id: 56,
    name: "Ludwig Mies van der Rohe",
    value: "Ludwig Mies van der Rohe",
  },
  { id: 57, name: "Paul Klee", value: "Paul Klee" },
  { id: 58, name: "Wassily Kandinsky", value: "Wassily Kandinsky" },
  { id: 59, name: "László Moholy-Nagy", value: "László Moholy-Nagy" },
  {
    id: 60,
    name: "▬▬▬▬▬ Color Field Painting (1950-1960, pure abstraction, color, shape)",
    value:
      "▬▬▬▬▬ Color Field Painting (1950-1960, pure abstraction, color, shape)",
  },
  { id: 61, name: "Alma Thomas", value: "Alma Thomas" },
  { id: 62, name: "Barnett Newman", value: "Barnett Newman" },
  { id: 63, name: "Clyfford Still", value: "Clyfford Still" },
  { id: 64, name: "Helen Frankenthaler", value: "Helen Frankenthaler" },
  { id: 65, name: "John Hoyland", value: "John Hoyland" },
  { id: 66, name: "Kenneth Noland", value: "Kenneth Noland" },
  { id: 67, name: "Mark Rothko", value: "Mark Rothko" },
  { id: 68, name: "Morris Louis", value: "Morris Louis" },
  { id: 69, name: "Sam Gilliam", value: "Sam Gilliam" },
  {
    id: 70,
    name: "▬▬▬▬▬ Conceptual (1960-1970, ideas and concepts over visual)",
    value: "▬▬▬▬▬ Conceptual (1960-1970, ideas and concepts over visual)",
  },
  { id: 71, name: "Hans Haacke", value: "Hans Haacke" },
  { id: 72, name: "John Baldessari", value: "John Baldessari" },
  { id: 73, name: "Joseph Kosuth", value: "Joseph Kosuth" },
  { id: 74, name: "Marina Abramović", value: "Marina Abramović" },
  { id: 75, name: "Mel Bochner", value: "Mel Bochner" },
  { id: 76, name: "On Kawara", value: "On Kawara" },
  { id: 77, name: "Sol LeWitt", value: "Sol LeWitt" },
  {
    id: 78,
    name: "▬▬▬▬▬ Constructivism (1915-1940, Soviet Union, Germany, art for social purposes)",
    value:
      "▬▬▬▬▬ Constructivism (1915-1940, Soviet Union, Germany, art for social purposes)",
  },
  { id: 79, name: "Alexander Rodchenko", value: "Alexander Rodchenko" },
  { id: 80, name: "El Lissitzky", value: "El Lissitzky" },
  { id: 81, name: "Liubov Popova", value: "Liubov Popova" },
  {
    id: 82,
    name: "▬▬▬▬▬ Cubism (1907, two-dimensional form of the canvas)",
    value: "▬▬▬▬▬ Cubism (1907, two-dimensional form of the canvas)",
  },
  { id: 83, name: "Pablo Picasso", value: "Pablo Picasso" },
  { id: 84, name: "Georges Braque", value: "Georges Braque" },
  { id: 85, name: "Fernand Léger", value: "Fernand Léger" },
  { id: 86, name: "Alexander Archipenko", value: "Alexander Archipenko" },
  {
    id: 87,
    name: "▬▬▬▬▬ Dada / Dadaism (1916-1924, WWI, remove logic)",
    value: "▬▬▬▬▬ Dada / Dadaism (1916-1924, WWI, remove logic)",
  },
  { id: 88, name: "Francois Picabia", value: "Francois Picabia" },
  { id: 89, name: "Hannah Höch", value: "Hannah Höch" },
  { id: 90, name: "Hans Arp", value: "Hans Arp" },
  { id: 91, name: "Man Ray", value: "Man Ray" },
  { id: 92, name: "Marcel Duchamp", value: "Marcel Duchamp" },
  { id: 93, name: "Sophie Taeuber-Arp", value: "Sophie Taeuber-Arp" },
  {
    id: 94,
    name: "▬▬▬▬▬ Expressionism (1905-1920, Germany and Austria, emotional experience)",
    value:
      "▬▬▬▬▬ Expressionism (1905-1920, Germany and Austria, emotional experience)",
  },
  { id: 95, name: "Vincent van Gogh", value: "Vincent van Gogh" },
  { id: 96, name: "Henri Matisse", value: "Henri Matisse" },
  { id: 97, name: "Edvard Munch", value: "Edvard Munch" },
  { id: 98, name: "Egon Schiele", value: "Egon Schiele" },
  { id: 99, name: "Oskar Kokoschka", value: "Oskar Kokoschka" },
  { id: 100, name: "Wassily Kandinsky", value: "Wassily Kandinsky" },
  { id: 101, name: "Franz Marc", value: "Franz Marc" },
  { id: 102, name: "Ernst Ludwig Kirchner", value: "Ernst Ludwig Kirchner" },
  {
    id: 103,
    name: "▬▬▬▬▬ Fauvism (vibrant color, brushstrokes)",
    value: "▬▬▬▬▬ Fauvism (vibrant color, brushstrokes)",
  },
  { id: 104, name: "André Derain", value: "André Derain" },
  { id: 105, name: "Henri Matisse", value: "Henri Matisse" },
  { id: 106, name: "Maurice de Vlaminck", value: "Maurice de Vlaminck" },
  {
    id: 107,
    name: "▬▬▬▬▬ Futurism (1908-1944, Italy, speed and energy of the modern world)",
    value:
      "▬▬▬▬▬ Futurism (1908-1944, Italy, speed and energy of the modern world)",
  },
  {
    id: 108,
    name: "Filippo Tommaso Marinetti",
    value: "Filippo Tommaso Marinetti",
  },
  { id: 109, name: "Giacomo Balla", value: "Giacomo Balla" },
  { id: 110, name: "Gino Severini", value: "Gino Severini" },
  { id: 111, name: "Luigi Russolo", value: "Luigi Russolo" },
  { id: 112, name: "Umberto Boccioni", value: "Umberto Boccioni" },
  {
    id: 113,
    name: "▬▬▬▬▬ Harlem Renaissance (1919-1930, Harlem NY, African American culture, identity, pride)",
    value:
      "▬▬▬▬▬ Harlem Renaissance (1919-1930, Harlem NY, African American culture, identity, pride)",
  },
  { id: 114, name: "Aaron Douglas", value: "Aaron Douglas" },
  { id: 115, name: "Archibald John Motley", value: "Archibald John Motley" },
  { id: 116, name: "Beauford Delaney", value: "Beauford Delaney" },
  { id: 117, name: "Jacob Lawrence", value: "Jacob Lawrence" },
  { id: 118, name: "James van der Zee", value: "James van der Zee" },
  {
    id: 119,
    name: "▬▬▬▬▬ Impressionism (1867-1886, Italy, record impressions, thin brush strokes, dreamy)",
    value:
      "▬▬▬▬▬ Impressionism (1867-1886, Italy, record impressions, thin brush strokes, dreamy)",
  },
  { id: 120, name: "Alfred Sisley", value: "Alfred Sisley" },
  { id: 121, name: "Camille Pissarro", value: "Camille Pissarro" },
  { id: 122, name: "Claude Monet", value: "Claude Monet" },
  { id: 123, name: "Edgar Degas", value: "Edgar Degas" },
  { id: 124, name: "Paul Cézanne", value: "Paul Cézanne" },
  { id: 125, name: "Pierre Auguste Renoir", value: "Pierre Auguste Renoir" },
  {
    id: 126,
    name: "▬▬▬▬▬ Installation Art (1950, physical, large, mixed media)",
    value: "▬▬▬▬▬ Installation Art (1950, physical, large, mixed media)",
  },
  { id: 127, name: "Doris Salcedo", value: "Doris Salcedo" },
  { id: 128, name: "Gordon Matta-Clark", value: "Gordon Matta-Clark" },
  { id: 129, name: "Jason Rhoades", value: "Jason Rhoades" },
  { id: 130, name: "Judy Chicago", value: "Judy Chicago" },
  { id: 131, name: "Kara Walker", value: "Kara Walker" },
  { id: 132, name: "Kurt Schwitters", value: "Kurt Schwitters" },
  { id: 133, name: "Marcel Broodthaers", value: "Marcel Broodthaers" },
  { id: 134, name: "Thomas Hirschhorn", value: "Thomas Hirschhorn" },
  { id: 135, name: "Yayoi Kusama", value: "Yayoi Kusama" },
  {
    id: 136,
    name: "▬▬▬▬▬ Land Art (1960, nature, shape and form and physical space)",
    value: "▬▬▬▬▬ Land Art (1960, nature, shape and form and physical space)",
  },
  { id: 137, name: "Ana Mendieta", value: "Ana Mendieta" },
  { id: 138, name: "Andy Goldsworthy", value: "Andy Goldsworthy" },
  {
    id: 139,
    name: "Christo and Jeanne-Claude",
    value: "Christo and Jeanne-Claude",
  },
  { id: 140, name: "Michael Heizer", value: "Michael Heizer" },
  { id: 141, name: "Richard Long", value: "Richard Long" },
  { id: 142, name: "Robert Smithson", value: "Robert Smithson" },
  {
    id: 143,
    name: "▬▬▬▬▬ Minimalism (1960-1970, simple, abstract, geometric shapes, no representation)",
    value:
      "▬▬▬▬▬ Minimalism (1960-1970, simple, abstract, geometric shapes, no representation)",
  },
  { id: 144, name: "Agnes Martin", value: "Agnes Martin" },
  { id: 145, name: "Carl Andre", value: "Carl Andre" },
  { id: 146, name: "Dan Flavin", value: "Dan Flavin" },
  { id: 147, name: "Donald Judd", value: "Donald Judd" },
  { id: 148, name: "Frank Stella", value: "Frank Stella" },
  { id: 149, name: "Larry Bell", value: "Larry Bell" },
  { id: 150, name: "Robert Irwin", value: "Robert Irwin" },
  { id: 151, name: "Sol Lewitt", value: "Sol Lewitt" },
  {
    id: 152,
    name: "▬▬▬▬▬ Neo-Impressionism (1880, France, systematic painting, pointillism)",
    value:
      "▬▬▬▬▬ Neo-Impressionism (1880, France, systematic painting, pointillism)",
  },
  { id: 153, name: "Georges Seurat", value: "Georges Seurat" },
  { id: 154, name: "Paul Signac", value: "Paul Signac" },
  {
    id: 155,
    name: "▬▬▬▬▬ Neon Art (1960, advertising as medium, neon lights)",
    value: "▬▬▬▬▬ Neon Art (1960, advertising as medium, neon lights)",
  },
  { id: 156, name: "Alex Da Corte", value: "Alex Da Corte" },
  { id: 157, name: "Dan Flavin", value: "Dan Flavin" },
  { id: 158, name: "Glenn Ligon", value: "Glenn Ligon" },
  { id: 159, name: "Joseph Kosuth", value: "Joseph Kosuth" },
  { id: 160, name: "Keith Sonnier", value: "Keith Sonnier" },
  {
    id: 161,
    name: "Tim Noble and Sue Webster",
    value: "Tim Noble and Sue Webster",
  },
  { id: 162, name: "Tracey Emin", value: "Tracey Emin" },
  {
    id: 163,
    name: "▬▬▬▬▬ Op Art (1950-1960, visual effects, optical illusion, perception)",
    value:
      "▬▬▬▬▬ Op Art (1950-1960, visual effects, optical illusion, perception)",
  },
  { id: 164, name: "Bridget Riley", value: "Bridget Riley" },
  { id: 165, name: "François Morellet", value: "François Morellet" },
  { id: 166, name: "Jesús Rafael Soto", value: "Jesús Rafael Soto" },
  { id: 167, name: "Julio Le Parc", value: "Julio Le Parc" },
  { id: 168, name: "Victor Vasarely", value: "Victor Vasarely" },
  {
    id: 169,
    name: "▬▬▬▬▬ Performance Art (1960, acting for an audience or camera, events, happenings)",
    value:
      "▬▬▬▬▬ Performance Art (1960, acting for an audience or camera, events, happenings)",
  },
  { id: 170, name: "Ana Mendieta", value: "Ana Mendieta" },
  { id: 171, name: "Guerilla Girls", value: "Guerilla Girls" },
  { id: 172, name: "Marina Abramovich", value: "Marina Abramovich" },
  { id: 173, name: "Nick Cave", value: "Nick Cave" },
  { id: 174, name: "Tehching Hsieh", value: "Tehching Hsieh" },
  { id: 175, name: "Yoko Ono", value: "Yoko Ono" },
  {
    id: 176,
    name: "▬▬▬▬▬ Pop Art (1950-1960, popular culture, commercial culture)",
    value: "▬▬▬▬▬ Pop Art (1950-1960, popular culture, commercial culture)",
  },
  { id: 177, name: "Andy Warhol", value: "Andy Warhol" },
  { id: 178, name: "Claes Oldenburg", value: "Claes Oldenburg" },
  { id: 179, name: "David Hockney", value: "David Hockney" },
  { id: 180, name: "Richard Hamilton", value: "Richard Hamilton" },
  { id: 181, name: "Robert Rauschenberg", value: "Robert Rauschenberg" },
  { id: 182, name: "Roy Lichtenstein", value: "Roy Lichtenstein" },
  {
    id: 183,
    name: "▬▬▬▬▬ Post-Impressionism (1886-1905, reaction against impressionism, bold colors, symbolic imagery)",
    value:
      "▬▬▬▬▬ Post-Impressionism (1886-1905, reaction against impressionism, bold colors, symbolic imagery)",
  },
  { id: 184, name: "Camille Pissarro", value: "Camille Pissarro" },
  { id: 185, name: "Georges Seurat", value: "Georges Seurat" },
  { id: 186, name: "Henri Rousseau", value: "Henri Rousseau" },
  { id: 187, name: "Paul Gauguin", value: "Paul Gauguin" },
  { id: 188, name: "Toulouse Lautrec", value: "Toulouse Lautrec" },
  { id: 189, name: "Vincent van Gogh", value: "Vincent van Gogh" },
  {
    id: 190,
    name: "▬▬▬▬▬ Precisionism (1920-1930, cubism and futurism, sharp focus, industrialization)",
    value:
      "▬▬▬▬▬ Precisionism (1920-1930, cubism and futurism, sharp focus, industrialization)",
  },
  { id: 191, name: "Charles Demuth", value: "Charles Demuth" },
  { id: 192, name: "Charles Sheeler", value: "Charles Sheeler" },
  { id: 193, name: "Edward Hopper", value: "Edward Hopper" },
  { id: 194, name: "George Ault", value: "George Ault" },
  { id: 195, name: "Georgia O’Keeffe", value: "Georgia O’Keeffe" },
  { id: 196, name: "Joseph Stella", value: "Joseph Stella" },
  {
    id: 197,
    name: "▬▬▬▬▬ Street Art (1960s, graffiti, illegal art, murals, urban art)",
    value: "▬▬▬▬▬ Street Art (1960s, graffiti, illegal art, murals, urban art)",
  },
  { id: 198, name: "Banksy", value: "Banksy" },
  { id: 199, name: "Jean Michel Basquiat", value: "Jean Michel Basquiat" },
  { id: 200, name: "Keith Haring", value: "Keith Haring" },
  { id: 201, name: "Lady Pink", value: "Lady Pink" },
  { id: 202, name: "Shepard Fairey", value: "Shepard Fairey" },
  {
    id: 203,
    name: "▬▬▬▬▬ Surrealism (1920-1960, Paris, WWII, rational and irrational, dreams, poetry)",
    value:
      "▬▬▬▬▬ Surrealism (1920-1960, Paris, WWII, rational and irrational, dreams, poetry)",
  },
  { id: 204, name: "André Breton", value: "André Breton" },
  { id: 205, name: "André Masson", value: "André Masson" },
  { id: 206, name: "Diego Rivera", value: "Diego Rivera" },
  { id: 207, name: "Frida Kahlo", value: "Frida Kahlo" },
  { id: 208, name: "Jean Arp", value: "Jean Arp" },
  { id: 209, name: "Joan Miró", value: "Joan Miró" },
  { id: 210, name: "Man Ray", value: "Man Ray" },
  { id: 211, name: "Max Ernst", value: "Max Ernst" },
  { id: 212, name: "Meret Oppenheim", value: "Meret Oppenheim" },
  { id: 213, name: "Rene Magritte", value: "Rene Magritte" },
  { id: 214, name: "Salvador Dalí", value: "Salvador Dalí" },
  { id: 215, name: "Shane McGeehan", value: "Shane McGeehan" },
  { id: 216, name: "Wifredo Lam", value: "Wifredo Lam" },
  { id: 217, name: "Yves Tanguy", value: "Yves Tanguy" },
  {
    id: 218,
    name: "▬▬▬▬▬ Suprematism (1910-1920, Russia, simple geometric forms, color, very minimal)",
    value:
      "▬▬▬▬▬ Suprematism (1910-1920, Russia, simple geometric forms, color, very minimal)",
  },
  { id: 219, name: "El Lissitzky", value: "El Lissitzky" },
  { id: 220, name: "Kazimir Malevich", value: "Kazimir Malevich" },
  { id: 221, name: "Mikhail Menkov", value: "Mikhail Menkov" },
  {
    id: 222,
    name: "▬▬▬▬▬ Symbolism (1880-1920, objects as symbols, emotions, weariness of modernity)",
    value:
      "▬▬▬▬▬ Symbolism (1880-1920, objects as symbols, emotions, weariness of modernity)",
  },
  { id: 223, name: "Arnold Böcklin", value: "Arnold Böcklin" },
  { id: 224, name: "Edvard Munch", value: "Edvard Munch" },
  { id: 225, name: "Fernand Khnopff", value: "Fernand Khnopff" },
  { id: 226, name: "Gustav Klimt", value: "Gustav Klimt" },
  { id: 227, name: "Gustave Moreau", value: "Gustave Moreau" },
  { id: 228, name: "Jan Toorop", value: "Jan Toorop" },
  { id: 229, name: "Odilon Redon", value: "Odilon Redon" },
  { id: 230, name: "Paul Gauguin", value: "Paul Gauguin" },
  {
    id: 231,
    name: "▬▬▬▬▬ Zero Group (1950-1960, Germany, materiality, color, vibration, light, abstract)",
    value:
      "▬▬▬▬▬ Zero Group (1950-1960, Germany, materiality, color, vibration, light, abstract)",
  },
  { id: 232, name: "Günther Uecker", value: "Günther Uecker" },
  { id: 233, name: "Heinz Mack", value: "Heinz Mack" },
  { id: 234, name: "Jean Tinguely", value: "Jean Tinguely" },
  { id: 235, name: "Lucio Fontana", value: "Lucio Fontana" },
  { id: 236, name: "Otto Piene", value: "Otto Piene" },
  { id: 237, name: "Piero Manzoni", value: "Piero Manzoni" },
  { id: 238, name: "Yayoi Kusama", value: "Yayoi Kusama" },
  { id: 239, name: "Yves Klein", value: "Yves Klein" },
  {
    id: 240,
    name: "▬▬▬▬▬ Photography (oh you know, cameras and stuff since 1826, thanks Niépce)",
    value:
      "▬▬▬▬▬ Photography (oh you know, cameras and stuff since 1826, thanks Niépce)",
  },
  { id: 241, name: "Aaron Siskind", value: "Aaron Siskind" },
  { id: 242, name: "Alexander Rodchenko", value: "Alexander Rodchenko" },
  { id: 243, name: "Alfred Stieglitz", value: "Alfred Stieglitz" },
  { id: 244, name: "Alvin Langdon Coburn", value: "Alvin Langdon Coburn" },
  { id: 245, name: "Andre Kertesz", value: "Andre Kertesz" },
  { id: 246, name: "Andres Serrano", value: "Andres Serrano" },
  { id: 247, name: "Ansel Adams", value: "Ansel Adams" },
  { id: 248, name: "Arnold Newman", value: "Arnold Newman" },
  { id: 249, name: "August Sander", value: "August Sander" },
  { id: 250, name: "Barbara Kruger", value: "Barbara Kruger" },
  { id: 251, name: "Berenice Abbott", value: "Berenice Abbott" },
  {
    id: 252,
    name: "Bernd and Hilla Becher",
    value: "Bernd and Hilla Becher",
  },
  { id: 253, name: "Bill Brandt", value: "Bill Brandt" },
  { id: 254, name: "Brassai", value: "Brassai" },
  { id: 255, name: "Carleton E Watkins", value: "Carleton E Watkins" },
  { id: 256, name: "Carrie Mae Weems", value: "Carrie Mae Weems" },
  { id: 257, name: "Cindy Sherman", value: "Cindy Sherman" },
  {
    id: 258,
    name: "Clarence John Laughlin",
    value: "Clarence John Laughlin",
  },
  { id: 259, name: "David Levinthal", value: "David Levinthal" },
  { id: 260, name: "Dawoud Bey", value: "Dawoud Bey" },
  { id: 261, name: "Diane Arbus", value: "Diane Arbus" },
  { id: 262, name: "Dorothea Lange", value: "Dorothea Lange" },
  { id: 263, name: "Duane Michals", value: "Duane Michals" },
  { id: 264, name: "Eadweard Muybridge", value: "Eadweard Muybridge" },
  { id: 265, name: "Edward Burtynsky", value: "Edward Burtynsky" },
  { id: 266, name: "Edward Steichen", value: "Edward Steichen" },
  { id: 267, name: "Edward Weston", value: "Edward Weston" },
  { id: 268, name: "Emmet Gowin", value: "Emmet Gowin" },
  { id: 269, name: "Eugene Atget", value: "Eugene Atget" },
  { id: 270, name: "Eugène Atget", value: "Eugène Atget" },
  { id: 271, name: "Francesca Woodman", value: "Francesca Woodman" },
  { id: 272, name: "Garry Winogrand", value: "Garry Winogrand" },
  { id: 273, name: "Garry Winogrand", value: "Garry Winogrand" },
  { id: 274, name: "Gertrude Käsebier", value: "Gertrude Käsebier" },
  { id: 275, name: "Gordon Parks", value: "Gordon Parks" },
  { id: 276, name: "Gustave Le Gray", value: "Gustave Le Gray" },
  { id: 277, name: "Hans Bellmer", value: "Hans Bellmer" },
  { id: 278, name: "Harold Edgerton", value: "Harold Edgerton" },
  { id: 279, name: "Harry Callahan", value: "Harry Callahan" },
  { id: 280, name: "Helen Levitt", value: "Helen Levitt" },
  { id: 281, name: "Henry Peach Robinson", value: "Henry Peach Robinson" },
  { id: 282, name: "Hiroshi Sugimoto", value: "Hiroshi Sugimoto" },
  { id: 283, name: "Imogen Cunningham", value: "Imogen Cunningham" },
  { id: 284, name: "Jacob Riis", value: "Jacob Riis" },
  {
    id: 285,
    name: "Jacques Henri Lartigue",
    value: "Jacques Henri Lartigue",
  },
  { id: 286, name: "Jerry Uelsmann", value: "Jerry Uelsmann" },
  { id: 287, name: "Joel Meyerowitz", value: "Joel Meyerowitz" },
  { id: 288, name: "Joel Peter Witkin", value: "Joel Peter Witkin" },
  { id: 289, name: "John Gutmann", value: "John Gutmann" },
  { id: 290, name: "Josef Koudelka", value: "Josef Koudelka" },
  {
    id: 291,
    name: "Julia Margaret Cameron",
    value: "Julia Margaret Cameron",
  },
  { id: 292, name: "Kahn and Selesnick", value: "Kahn and Selesnick" },
  { id: 293, name: "Karl Blossfeldt", value: "Karl Blossfeldt" },
  { id: 294, name: "László Moholy-Nagy", value: "László Moholy-Nagy" },
  { id: 295, name: "Lee Friedlander", value: "Lee Friedlander" },
  { id: 296, name: "Lewis Carroll", value: "Lewis Carroll" },
  { id: 297, name: "Lewis Hine", value: "Lewis Hine" },
  { id: 298, name: "Lisette Model", value: "Lisette Model" },
  { id: 299, name: "Lothar Wolleh", value: "Lothar Wolleh" },
  { id: 300, name: "Louis Daguerre", value: "Louis Daguerre" },
  { id: 301, name: "Margaret Bourke White", value: "Margaret Bourke White" },
  { id: 302, name: "Matthew Brandt", value: "Matthew Brandt" },
  { id: 303, name: "Minor White", value: "Minor White" },
  { id: 304, name: "Nadar", value: "Nadar" },
  { id: 305, name: "Paul Outerbridge", value: "Paul Outerbridge" },
  { id: 306, name: "Paul Strand", value: "Paul Strand" },
  { id: 307, name: "Ralph Eugene Meatyard", value: "Ralph Eugene Meatyard" },
  { id: 308, name: "Richard Avedon", value: "Richard Avedon" },
  { id: 309, name: "Robert Adams", value: "Robert Adams" },
  {
    id: 310,
    name: "Robert and Shana ParkeHarrison",
    value: "Robert and Shana ParkeHarrison",
  },
  { id: 311, name: "Robert Frank", value: "Robert Frank" },
  { id: 312, name: "Roger Fenton", value: "Roger Fenton" },
  { id: 313, name: "Roy DeCarava", value: "Roy DeCarava" },
  { id: 314, name: "Sandy Skoglund", value: "Sandy Skoglund" },
  { id: 315, name: "Sebastiao Salgado", value: "Sebastiao Salgado" },
  { id: 316, name: "Shane McGeehan", value: "Shane McGeehan" },
  { id: 317, name: "Sophie Calle", value: "Sophie Calle" },
  { id: 318, name: "Stephen Shore", value: "Stephen Shore" },
  { id: 319, name: "Timothy O'Sullivan", value: "Timothy O'Sullivan" },
  { id: 320, name: "Tina Modotti", value: "Tina Modotti" },
  { id: 321, name: "W Eugene Smith", value: "W Eugene Smith" },
  { id: 322, name: "Walker Evans", value: "Walker Evans" },
  { id: 323, name: "Weegee", value: "Weegee" },
  { id: 324, name: "William Eggleston", value: "William Eggleston" },
  {
    id: 325,
    name: "William Henry Fox Talbot",
    value: "William Henry Fox Talbot",
  },
  { id: 326, name: "William Klein", value: "William Klein" },
  { id: 327, name: "Yousuf Karsh", value: "Yousuf Karsh" },
  {
    id: 328,
    name: "▬▬▬▬▬ AI Trained Artists (list from Sincarnate)",
    value: "▬▬▬▬▬ AI Trained Artists (list from Sincarnate)",
  },
  { id: 329, name: "Aaron Horkey", value: "Aaron Horkey" },
  { id: 330, name: "Abigail Larson", value: "Abigail Larson" },
  { id: 331, name: "Adolph Menzel", value: "Adolph Menzel" },
  { id: 332, name: "Affandi", value: "Affandi" },
  { id: 333, name: "Agnes Lawrence Pelton", value: "Agnes Lawrence Pelton" },
  { id: 334, name: "Akihiko Yoshida", value: "Akihiko Yoshida" },
  { id: 335, name: "Akira Toriyama", value: "Akira Toriyama" },
  { id: 336, name: "Al Williamson", value: "Al Williamson" },
  { id: 337, name: "Alan Lee", value: "Alan Lee" },
  { id: 338, name: "Albert Bierstadt", value: "Albert Bierstadt" },
  { id: 339, name: "Alberto Giacometti", value: "Alberto Giacometti" },
  { id: 340, name: "Alberto Vargas", value: "Alberto Vargas" },
  { id: 341, name: "Albrecht Dürer", value: "Albrecht Dürer" },
  { id: 342, name: "Aleksi Briclot", value: "Aleksi Briclot" },
  { id: 343, name: "Alena Aenami", value: "Alena Aenami" },
  { id: 344, name: "Alex Grey", value: "Alex Grey" },
  { id: 345, name: "Alexander Jansson", value: "Alexander Jansson" },
  {
    id: 346,
    name: "Alexander Milne Calder",
    value: "Alexander Milne Calder",
  },
  { id: 347, name: "Alexandre Cabanel", value: "Alexandre Cabanel" },
  { id: 348, name: "Alexei Savrasov", value: "Alexei Savrasov" },
  { id: 349, name: "Alexej von Jawlensky", value: "Alexej von Jawlensky" },
  { id: 350, name: "Alfred Kubin", value: "Alfred Kubin" },
  { id: 351, name: "Alice Neel", value: "Alice Neel" },
  { id: 352, name: "Alphonso Mucha", value: "Alphonso Mucha" },
  { id: 353, name: "Alyssa Monks", value: "Alyssa Monks" },
  { id: 354, name: "Amanda Sage", value: "Amanda Sage" },
  { id: 355, name: "Amedeo Modigliani", value: "Amedeo Modigliani" },
  { id: 356, name: "André Masson", value: "André Masson" },
  { id: 357, name: "Andreas Rocha", value: "Andreas Rocha" },
  { id: 358, name: "Andrew Wyeth", value: "Andrew Wyeth" },
  { id: 359, name: "Andy Warhol", value: "Andy Warhol" },
  { id: 360, name: "Angus McKie", value: "Angus McKie" },
  { id: 361, name: "Anna Dittmann", value: "Anna Dittmann" },
  { id: 362, name: "Anne Geddes", value: "Anne Geddes" },
  { id: 363, name: "Anne McCaffrey", value: "Anne McCaffrey" },
  { id: 364, name: "Anne Stokes", value: "Anne Stokes" },
  { id: 365, name: "Annibale Carracci", value: "Annibale Carracci" },
  { id: 366, name: "Ansel Adams", value: "Ansel Adams" },
  { id: 367, name: "Anthony van Dyck", value: "Anthony van Dyck" },
  { id: 368, name: "Anton Otto Fischer", value: "Anton Otto Fischer" },
  { id: 369, name: "Anton Pieck", value: "Anton Pieck" },
  { id: 370, name: "Apollonia Saintclair", value: "Apollonia Saintclair" },
  { id: 371, name: "Arkhip Kuindzhi", value: "Arkhip Kuindzhi" },
  { id: 372, name: "Arnold Böcklin", value: "Arnold Böcklin" },
  { id: 373, name: "Arshile Gorky", value: "Arshile Gorky" },
  { id: 374, name: "Art Spiegelman", value: "Art Spiegelman" },
  { id: 375, name: "Artemisia Gentileschi", value: "Artemisia Gentileschi" },
  { id: 376, name: "Artgerm", value: "Artgerm" },
  { id: 377, name: "Arthur Dove", value: "Arthur Dove" },
  { id: 378, name: "Arthur Rackham", value: "Arthur Rackham" },
  { id: 379, name: "Asaf Hanuka", value: "Asaf Hanuka" },
  { id: 380, name: "Asher Brown Durand", value: "Asher Brown Durand" },
  { id: 381, name: "Ashley Wood", value: "Ashley Wood" },
  { id: 382, name: "Atey Ghailan", value: "Atey Ghailan" },
  { id: 383, name: "Aubrey Beardsley", value: "Aubrey Beardsley" },
  { id: 384, name: "Audrey Kawasaki", value: "Audrey Kawasaki" },
  { id: 385, name: "August Macke", value: "August Macke" },
  {
    id: 386,
    name: "Augustus Edwin Mulready",
    value: "Augustus Edwin Mulready",
  },
  { id: 387, name: "Austin Briggs", value: "Austin Briggs" },
  { id: 388, name: "Ayami Kojima", value: "Ayami Kojima" },
  { id: 389, name: "Barclay Shaw", value: "Barclay Shaw" },
  {
    id: 390,
    name: "Bastien Lecouffe-Deharme",
    value: "Bastien Lecouffe-Deharme",
  },
  { id: 391, name: "Bauhaus", value: "Bauhaus" },
  { id: 392, name: "Beatrix Potter", value: "Beatrix Potter" },
  { id: 393, name: "Beauford Delaney", value: "Beauford Delaney" },
  { id: 394, name: "Ben Shahn", value: "Ben Shahn" },
  { id: 395, name: "Ben Templesmith", value: "Ben Templesmith" },
  { id: 396, name: "Benoit B. Mandelbrot", value: "Benoit B. Mandelbrot" },
  { id: 397, name: "Bernard Buffet", value: "Bernard Buffet" },
  { id: 398, name: "Bernie Wrightson", value: "Bernie Wrightson" },
  { id: 399, name: "Bjarke Ingels", value: "Bjarke Ingels" },
  { id: 400, name: "Bob Byerley", value: "Bob Byerley" },
  { id: 401, name: "Bob Eggleton", value: "Bob Eggleton" },
  { id: 402, name: "Bob Ross", value: "Bob Ross" },
  { id: 403, name: "Bonnard Pierre", value: "Bonnard Pierre" },
  { id: 404, name: "Boris Vallejo", value: "Boris Vallejo" },
  { id: 405, name: "Brian Bolland", value: "Brian Bolland" },
  { id: 406, name: "Brian Despain", value: "Brian Despain" },
  { id: 407, name: "Brian Froud", value: "Brian Froud" },
  { id: 408, name: "Brian Kesinger", value: "Brian Kesinger" },
  { id: 409, name: "Bridget Bate Tichenor", value: "Bridget Bate Tichenor" },
  { id: 410, name: "Bridget Riley", value: "Bridget Riley" },
  { id: 411, name: "Brothers Hildebrandt", value: "Brothers Hildebrandt" },
  { id: 412, name: "Bruce Pennington", value: "Bruce Pennington" },
  { id: 413, name: "Camille Corot", value: "Camille Corot" },
  { id: 414, name: "Camille Pissarro", value: "Camille Pissarro" },
  {
    id: 415,
    name: "Camille-Pierre Pambu Bodo",
    value: "Camille-Pierre Pambu Bodo",
  },
  { id: 416, name: "Canaletto", value: "Canaletto" },
  { id: 417, name: "Candido Portinari", value: "Candido Portinari" },
  { id: 418, name: "Caravaggio", value: "Caravaggio" },
  { id: 419, name: "Carl Barks", value: "Carl Barks" },
  { id: 420, name: "Carl Holsoe", value: "Carl Holsoe" },
  { id: 421, name: "Carl Larsson", value: "Carl Larsson" },
  { id: 422, name: "Carne Griffiths", value: "Carne Griffiths" },
  {
    id: 423,
    name: "Caspar David Friedrich",
    value: "Caspar David Friedrich",
  },
  { id: 424, name: "cgsociety", value: "cgsociety" },
  { id: 425, name: "Chaïm Soutine", value: "Chaïm Soutine" },
  { id: 426, name: "Charles Blackman", value: "Charles Blackman" },
  { id: 427, name: "Charles E. Burchfield", value: "Charles E. Burchfield" },
  { id: 428, name: "Charles Eames", value: "Charles Eames" },
  { id: 429, name: "Charles Schulz", value: "Charles Schulz" },
  { id: 430, name: "Charlie Bowater", value: "Charlie Bowater" },
  { id: 431, name: "Chesley Bonestell", value: "Chesley Bonestell" },
  { id: 432, name: "Chiharu Shiota", value: "Chiharu Shiota" },
  { id: 433, name: "Childe Hassam", value: "Childe Hassam" },
  { id: 434, name: "Chris Foss", value: "Chris Foss" },
  { id: 435, name: "Chris Mars", value: "Chris Mars" },
  { id: 436, name: "Chris Moore", value: "Chris Moore" },
  { id: 437, name: "Cicely Mary Barker", value: "Cicely Mary Barker" },
  { id: 438, name: "Cindy Sherman", value: "Cindy Sherman" },
  { id: 439, name: "Claude Cahun", value: "Claude Cahun" },
  { id: 440, name: "Claude Lorrain", value: "Claude Lorrain" },
  { id: 441, name: "Claude Monet", value: "Claude Monet" },
  { id: 442, name: "Clyde Caldwell", value: "Clyde Caldwell" },
  { id: 443, name: "Coby Whitmore", value: "Coby Whitmore" },
  { id: 444, name: "Codex Seraphinianus", value: "Codex Seraphinianus" },
  { id: 445, name: "Coles Phillips", value: "Coles Phillips" },
  { id: 446, name: "Conrad Roset", value: "Conrad Roset" },
  { id: 447, name: "Cory Loftis", value: "Cory Loftis" },
  { id: 448, name: "Craig Davison", value: "Craig Davison" },
  { id: 449, name: "Craig Mullins", value: "Craig Mullins" },
  { id: 450, name: "Craola", value: "Craola" },
  { id: 451, name: "Cuno Amiet", value: "Cuno Amiet" },
  { id: 452, name: "Dan Flavin", value: "Dan Flavin" },
  { id: 453, name: "Dan Mumford", value: "Dan Mumford" },
  { id: 454, name: "Daniel Gerhartz", value: "Daniel Gerhartz" },
  { id: 455, name: "Daniel Merriam", value: "Daniel Merriam" },
  { id: 456, name: "Daniel Ridgway Knight", value: "Daniel Ridgway Knight" },
  { id: 457, name: "Dave Dorman", value: "Dave Dorman" },
  { id: 458, name: "Dave Gibbons", value: "Dave Gibbons" },
  { id: 459, name: "Dave McKean", value: "Dave McKean" },
  { id: 460, name: "David B. Mattingly", value: "David B. Mattingly" },
  { id: 461, name: "David Burliuk", value: "David Burliuk" },
  { id: 462, name: "David Hockney", value: "David Hockney" },
  { id: 463, name: "Dean Ellis", value: "Dean Ellis" },
  { id: 464, name: "Diane Arbus", value: "Diane Arbus" },
  { id: 465, name: "Diego Rivera", value: "Diego Rivera" },
  { id: 466, name: "Diego Velázquez", value: "Diego Velázquez" },
  { id: 467, name: "Don Bluth", value: "Don Bluth" },
  { id: 468, name: "Don Maitz", value: "Don Maitz" },
  { id: 469, name: "Donato Giancola", value: "Donato Giancola" },
  { id: 470, name: "Dora Maar", value: "Dora Maar" },
  { id: 471, name: "Dorothea Tanning", value: "Dorothea Tanning" },
  { id: 472, name: "Dorothy Lathrop", value: "Dorothy Lathrop" },
  { id: 473, name: "Doug Chiang", value: "Doug Chiang" },
  { id: 474, name: "Dr. Seuss", value: "Dr. Seuss" },
  { id: 475, name: "Dustin Nguyen", value: "Dustin Nguyen" },
  { id: 476, name: "Earl Norem", value: "Earl Norem" },
  { id: 477, name: "Ed Binkley", value: "Ed Binkley" },
  { id: 478, name: "Ed Emshwiller", value: "Ed Emshwiller" },
  { id: 479, name: "Eddie Campbell", value: "Eddie Campbell" },
  { id: 480, name: "Eddie Mendoza", value: "Eddie Mendoza" },
  { id: 481, name: "Edgar Degas", value: "Edgar Degas" },
  { id: 482, name: "Edmund Dulac", value: "Edmund Dulac" },
  { id: 483, name: "Edmund Leighton", value: "Edmund Leighton" },
  { id: 484, name: "Édouard Manet", value: "Édouard Manet" },
  { id: 485, name: "Edvard Munch", value: "Edvard Munch" },
  { id: 486, name: "Edward Burne-Jones", value: "Edward Burne-Jones" },
  { id: 487, name: "Edward Gorey", value: "Edward Gorey" },
  { id: 488, name: "Edward Hopper", value: "Edward Hopper" },
  { id: 489, name: "Edward Lear", value: "Edward Lear" },
  { id: 490, name: "Edward Robert Hughes", value: "Edward Robert Hughes" },
  { id: 491, name: "Edward Weston", value: "Edward Weston" },
  { id: 492, name: "Egon Schiele", value: "Egon Schiele" },
  { id: 493, name: "El Greco", value: "El Greco" },
  { id: 494, name: "Elaine de Kooning", value: "Elaine de Kooning" },
  { id: 495, name: "Ellen Jewett", value: "Ellen Jewett" },
  { id: 496, name: "Elliott Erwitt", value: "Elliott Erwitt" },
  { id: 497, name: "Elsa Beskow", value: "Elsa Beskow" },
  { id: 498, name: "Emil Melmoth", value: "Emil Melmoth" },
  { id: 499, name: "Emil Nolde", value: "Emil Nolde" },
  { id: 500, name: "Émile Bernard", value: "Émile Bernard" },
  { id: 501, name: "Enki Bilal", value: "Enki Bilal" },
  { id: 502, name: "Eric Lacombe", value: "Eric Lacombe" },
  { id: 503, name: "Erich Heckel", value: "Erich Heckel" },
  { id: 504, name: "Ernst Fuchs", value: "Ernst Fuchs" },
  { id: 505, name: "Ernst Haeckel", value: "Ernst Haeckel" },
  { id: 506, name: "Ernst Ludwig Kirchner", value: "Ernst Ludwig Kirchner" },
  { id: 507, name: "Esao Andrews", value: "Esao Andrews" },
  { id: 508, name: "Eugene Delacroix", value: "Eugene Delacroix" },
  { id: 509, name: "Evelyn De Morgan", value: "Evelyn De Morgan" },
  { id: 510, name: "Eyvind Earle", value: "Eyvind Earle" },
  { id: 511, name: "Farel Dalrymple", value: "Farel Dalrymple" },
  { id: 512, name: "Fenghua Zhong", value: "Fenghua Zhong" },
  { id: 513, name: "Fernando Botero", value: "Fernando Botero" },
  { id: 514, name: "Filippino Lippi", value: "Filippino Lippi" },
  { id: 515, name: "Francis Bacon", value: "Francis Bacon" },
  { id: 516, name: "Francis Picabia", value: "Francis Picabia" },
  { id: 517, name: "Francisco Goya", value: "Francisco Goya" },
  { id: 518, name: "Frank Auerbach", value: "Frank Auerbach" },
  { id: 519, name: "Frank Frazetta", value: "Frank Frazetta" },
  { id: 520, name: "Frank Miller", value: "Frank Miller" },
  { id: 521, name: "Frank Schoonover", value: "Frank Schoonover" },
  { id: 522, name: "Frank Zappa", value: "Frank Zappa" },
  { id: 523, name: "Franklin Booth", value: "Franklin Booth" },
  { id: 524, name: "Franz Marc", value: "Franz Marc" },
  { id: 525, name: "Franz Sedlacek", value: "Franz Sedlacek" },
  {
    id: 526,
    name: "Franz Xaver Winterhalter",
    value: "Franz Xaver Winterhalter",
  },
  { id: 527, name: "Fred Tomaselli", value: "Fred Tomaselli" },
  {
    id: 528,
    name: "Frederick Lord Leighton",
    value: "Frederick Lord Leighton",
  },
  { id: 529, name: "Frida Kahlo", value: "Frida Kahlo" },
  {
    id: 530,
    name: "Friedensreich Regentag Dunkelbunt Hundertwasser",
    value: "Friedensreich Regentag Dunkelbunt Hundertwasser",
  },
  { id: 531, name: "Frits Van den Berghe", value: "Frits Van den Berghe" },
  { id: 532, name: "Gabriele Münter", value: "Gabriele Münter" },
  { id: 533, name: "Garry Trudeau", value: "Garry Trudeau" },
  { id: 534, name: "Gary Larson", value: "Gary Larson" },
  { id: 535, name: "Gaston Bussière", value: "Gaston Bussière" },
  {
    id: 536,
    name: "Gediminas Pranckevicius",
    value: "Gediminas Pranckevicius",
  },
  { id: 537, name: "Geof Darrow", value: "Geof Darrow" },
  { id: 538, name: "George Frederic Watts", value: "George Frederic Watts" },
  { id: 539, name: "George Grosz", value: "George Grosz" },
  { id: 540, name: "George Inness", value: "George Inness" },
  { id: 541, name: "George Luks", value: "George Luks" },
  { id: 542, name: "Georges Rouault", value: "Georges Rouault" },
  { id: 543, name: "Georges Seurat", value: "Georges Seurat" },
  { id: 544, name: "Georgia O’Keeffe", value: "Georgia O’Keeffe" },
  { id: 545, name: "Gerald Brom", value: "Gerald Brom" },
  { id: 546, name: "Gerhard Munthe", value: "Gerhard Munthe" },
  { id: 547, name: "Gerhard Richter", value: "Gerhard Richter" },
  { id: 548, name: "Giorgio de Chirico", value: "Giorgio de Chirico" },
  {
    id: 549,
    name: "Giovanni Battista Gaulli",
    value: "Giovanni Battista Gaulli",
  },
  {
    id: 550,
    name: "Giovanni Battista Piranesi",
    value: "Giovanni Battista Piranesi",
  },
  { id: 551, name: "Giuseppe Arcimboldo", value: "Giuseppe Arcimboldo" },
  { id: 552, name: "Glenn Fabry", value: "Glenn Fabry" },
  { id: 553, name: "Go Nagai", value: "Go Nagai" },
  { id: 554, name: "Gottfried Helnwein", value: "Gottfried Helnwein" },
  { id: 555, name: "Greg Hildebrandt", value: "Greg Hildebrandt" },
  { id: 556, name: "Greg Rutkowski", value: "Greg Rutkowski" },
  { id: 557, name: "Gregory Crewdson", value: "Gregory Crewdson" },
  {
    id: 558,
    name: "Guido Borelli da Caluso",
    value: "Guido Borelli da Caluso",
  },
  { id: 559, name: "Gustav Klimt", value: "Gustav Klimt" },
  { id: 560, name: "Gustav Vigeland", value: "Gustav Vigeland" },
  { id: 561, name: "Gustave Courbet", value: "Gustave Courbet" },
  { id: 562, name: "Gustave Doré", value: "Gustave Doré" },
  { id: 563, name: "Gustave Moreau", value: "Gustave Moreau" },
  {
    id: 564,
    name: "Gustave Van de Woestijne",
    value: "Gustave Van de Woestijne",
  },
  { id: 565, name: "H. R. Giger", value: "H. R. Giger" },
  { id: 566, name: "H.P. Lovecraft", value: "H.P. Lovecraft" },
  { id: 567, name: "Hal Foster", value: "Hal Foster" },
  { id: 568, name: "Hannah Hoch", value: "Hannah Hoch" },
  { id: 569, name: "Hans Baldung", value: "Hans Baldung" },
  { id: 570, name: "Hans Bellmer", value: "Hans Bellmer" },
  { id: 571, name: "Harold Elliott", value: "Harold Elliott" },
  { id: 572, name: "Harriet Backer", value: "Harriet Backer" },
  { id: 573, name: "Harry Clarke", value: "Harry Clarke" },
  { id: 574, name: "Heinrich Kley", value: "Heinrich Kley" },
  { id: 575, name: "Heinrich Lefler", value: "Heinrich Lefler" },
  { id: 576, name: "Helmut Newton", value: "Helmut Newton" },
  { id: 577, name: "Hendrik Kerstens", value: "Hendrik Kerstens" },
  {
    id: 578,
    name: "Henri de Toulouse-Lautrec",
    value: "Henri de Toulouse-Lautrec",
  },
  { id: 579, name: "Henri Harpignies", value: "Henri Harpignies" },
  { id: 580, name: "Henri Matisse", value: "Henri Matisse" },
  { id: 581, name: "Henri Rousseau", value: "Henri Rousseau" },
  { id: 582, name: "Henri-Edmond Cross", value: "Henri-Edmond Cross" },
  { id: 583, name: "Henry Fuseli", value: "Henry Fuseli" },
  { id: 584, name: "Henry Ossawa Tanner", value: "Henry Ossawa Tanner" },
  { id: 585, name: "Hieronymus Bosch", value: "Hieronymus Bosch" },
  { id: 586, name: "Hilma af Klint", value: "Hilma af Klint" },
  { id: 587, name: "Hirohiko Araki", value: "Hirohiko Araki" },
  { id: 588, name: "Hiromu Arakawa", value: "Hiromu Arakawa" },
  { id: 589, name: "Hiroshi Nagai", value: "Hiroshi Nagai" },
  { id: 590, name: "Hiroshi Yoshida", value: "Hiroshi Yoshida" },
  { id: 591, name: "Hong SoonSang", value: "Hong SoonSang" },
  { id: 592, name: "Honoré Daumier", value: "Honoré Daumier" },
  { id: 593, name: "Hope Gangloff", value: "Hope Gangloff" },
  { id: 594, name: "Howard Finster", value: "Howard Finster" },
  { id: 595, name: "Howard Hodgkin", value: "Howard Hodgkin" },
  { id: 596, name: "Hubert Robert", value: "Hubert Robert" },
  { id: 597, name: "Hugh Ferriss", value: "Hugh Ferriss" },
  { id: 598, name: "Hugh Kretschmer", value: "Hugh Kretschmer" },
  { id: 599, name: "Hundertwasser", value: "Hundertwasser" },
  { id: 600, name: "Ian McQue", value: "Ian McQue" },
  { id: 601, name: "Ian Miller", value: "Ian Miller" },
  { id: 602, name: "Igor Morski", value: "Igor Morski" },
  { id: 603, name: "Ilya Kuvshinov", value: "Ilya Kuvshinov" },
  { id: 604, name: "Ilya Repin", value: "Ilya Repin" },
  { id: 605, name: "Irma Stern", value: "Irma Stern" },
  { id: 606, name: "Isaac Levitan", value: "Isaac Levitan" },
  { id: 607, name: "Ismail Inceoglu", value: "Ismail Inceoglu" },
  { id: 608, name: "Ivan Aivazovsky", value: "Ivan Aivazovsky" },
  { id: 609, name: "Ivan Albright", value: "Ivan Albright" },
  { id: 610, name: "Ivan Bilibin", value: "Ivan Bilibin" },
  { id: 611, name: "Ivan Shishkin", value: "Ivan Shishkin" },
  { id: 612, name: "J. J. Grandville", value: "J. J. Grandville" },
  { id: 613, name: "J.C. Leyendecker", value: "J.C. Leyendecker" },
  { id: 614, name: "J.M.W. Turner", value: "J.M.W. Turner" },
  { id: 615, name: "Jacek Yerka", value: "Jacek Yerka" },
  { id: 616, name: "Jack Butler Yeats", value: "Jack Butler Yeats" },
  { id: 617, name: "Jack Davis", value: "Jack Davis" },
  { id: 618, name: "Jack Gaughan", value: "Jack Gaughan" },
  { id: 619, name: "Jack Kirby", value: "Jack Kirby" },
  { id: 620, name: "Jackson Pollock", value: "Jackson Pollock" },
  { id: 621, name: "Jacob van Ruisdael", value: "Jacob van Ruisdael" },
  { id: 622, name: "Jakub Rozalski", value: "Jakub Rozalski" },
  {
    id: 623,
    name: "James Abbott McNeill Whistler",
    value: "James Abbott McNeill Whistler",
  },
  { id: 624, name: "James C. Christensen", value: "James C. Christensen" },
  { id: 625, name: "James Ensor", value: "James Ensor" },
  { id: 626, name: "James Gurney", value: "James Gurney" },
  { id: 627, name: "James Jean", value: "James Jean" },
  { id: 628, name: "James Turrell", value: "James Turrell" },
  { id: 629, name: "Jamie Hewlett", value: "Jamie Hewlett" },
  { id: 630, name: "Jan van Goyen", value: "Jan van Goyen" },
  { id: 631, name: "Jaroslaw Jasnikowski", value: "Jaroslaw Jasnikowski" },
  {
    id: 632,
    name: "Jasmine Becket-Griffith",
    value: "Jasmine Becket-Griffith",
  },
  { id: 633, name: "Jason Edmiston", value: "Jason Edmiston" },
  { id: 634, name: "Jason Limon", value: "Jason Limon" },
  { id: 635, name: "Jean Arp", value: "Jean Arp" },
  { id: 636, name: "Jean Delville", value: "Jean Delville" },
  { id: 637, name: "Jean Giraud", value: "Jean Giraud" },
  { id: 638, name: "Jean Metzinger", value: "Jean Metzinger" },
  { id: 639, name: "Jean-Antoine Watteau", value: "Jean-Antoine Watteau" },
  { id: 640, name: "Jean-Baptiste Monge", value: "Jean-Baptiste Monge" },
  { id: 641, name: "Jean-Honoré Fragonard", value: "Jean-Honoré Fragonard" },
  { id: 642, name: "Jean-Michel Basquiat", value: "Jean-Michel Basquiat" },
  { id: 643, name: "Jean-Pierre Vasarely", value: "Jean-Pierre Vasarely" },
  { id: 644, name: "Jeff Easley", value: "Jeff Easley" },
  { id: 645, name: "Jeff Koons", value: "Jeff Koons" },
  { id: 646, name: "Jeff Lemire", value: "Jeff Lemire" },
  {
    id: 647,
    name: "Jeffrey Catherine Jones",
    value: "Jeffrey Catherine Jones",
  },
  { id: 648, name: "Jeffrey Smith", value: "Jeffrey Smith" },
  { id: 649, name: "Jeffrey T. Larson", value: "Jeffrey T. Larson" },
  { id: 650, name: "Jeremy Geddes", value: "Jeremy Geddes" },
  { id: 651, name: "Jeremy Lipking", value: "Jeremy Lipking" },
  { id: 652, name: "Jeremy Mann", value: "Jeremy Mann" },
  { id: 653, name: "Jesper Ejsing", value: "Jesper Ejsing" },
  { id: 654, name: "Jim Burns", value: "Jim Burns" },
  { id: 655, name: "Jim Fitzpatrick", value: "Jim Fitzpatrick" },
  { id: 656, name: "Jim Lee", value: "Jim Lee" },
  { id: 657, name: "Jim Steranko", value: "Jim Steranko" },
  { id: 658, name: "Joan Miro", value: "Joan Miro" },
  { id: 659, name: "Joaquín Sorolla", value: "Joaquín Sorolla" },
  { id: 660, name: "Johan Christian Dahl", value: "Johan Christian Dahl" },
  { id: 661, name: "Johannes Vermeer", value: "Johannes Vermeer" },
  { id: 662, name: "Johfra Bosschart", value: "Johfra Bosschart" },
  {
    id: 663,
    name: "John Atkinson Grimshaw",
    value: "John Atkinson Grimshaw",
  },
  { id: 664, name: "John Bauer", value: "John Bauer" },
  { id: 665, name: "John Berkey", value: "John Berkey" },
  { id: 666, name: "John Blanche", value: "John Blanche" },
  { id: 667, name: "John Constable", value: "John Constable" },
  { id: 668, name: "John Duncan", value: "John Duncan" },
  {
    id: 669,
    name: "John Frederick Kensett",
    value: "John Frederick Kensett",
  },
  { id: 670, name: "John Harris", value: "John Harris" },
  { id: 671, name: "John Howe", value: "John Howe" },
  { id: 672, name: "John Hoyland", value: "John Hoyland" },
  { id: 673, name: "John James Audubon", value: "John James Audubon" },
  { id: 674, name: "John Kenn Mortensen", value: "John Kenn Mortensen" },
  { id: 675, name: "John Martin", value: "John Martin" },
  { id: 676, name: "John Perceval", value: "John Perceval" },
  { id: 677, name: "John Philip Falter", value: "John Philip Falter" },
  { id: 678, name: "John Romita Jr", value: "John Romita Jr" },
  { id: 679, name: "John Singer Sargent", value: "John Singer Sargent" },
  { id: 680, name: "John Stephens", value: "John Stephens" },
  {
    id: 681,
    name: "John William Waterhouse",
    value: "John William Waterhouse",
  },
  { id: 682, name: "Jon Klassen", value: "Jon Klassen" },
  { id: 683, name: "Josan Gonzalez", value: "Josan Gonzalez" },
  { id: 684, name: "Joseph Cornell", value: "Joseph Cornell" },
  { id: 685, name: "Joseph Ducreux", value: "Joseph Ducreux" },
  { id: 686, name: "Joseph Stella", value: "Joseph Stella" },
  { id: 687, name: "Josephine Wall", value: "Josephine Wall" },
  { id: 688, name: "Jules Bastien-Lepage", value: "Jules Bastien-Lepage" },
  { id: 689, name: "Junji Ito", value: "Junji Ito" },
  { id: 690, name: "Justin Gerard", value: "Justin Gerard" },
  { id: 691, name: "Kaethe Butcher", value: "Kaethe Butcher" },
  { id: 692, name: "Kaja Foglio", value: "Kaja Foglio" },
  { id: 693, name: "Karl Blossfeldt", value: "Karl Blossfeldt" },
  { id: 694, name: "Karl Schmidt-Rottluff", value: "Karl Schmidt-Rottluff" },
  { id: 695, name: "Karol Bak", value: "Karol Bak" },
  { id: 696, name: "Kate Greenaway", value: "Kate Greenaway" },
  { id: 697, name: "Käthe Kollwitz", value: "Käthe Kollwitz" },
  { id: 698, name: "Kati Horna", value: "Kati Horna" },
  { id: 699, name: "Katsushika Hokusai", value: "Katsushika Hokusai" },
  { id: 700, name: "Kay Nielsen", value: "Kay Nielsen" },
  { id: 701, name: "Kay Sage", value: "Kay Sage" },
  { id: 702, name: "Kazimir Malevich", value: "Kazimir Malevich" },
  { id: 703, name: "Kehinde Wiley", value: "Kehinde Wiley" },
  { id: 704, name: "Keith Haring", value: "Keith Haring" },
  { id: 705, name: "Kelly Freas", value: "Kelly Freas" },
  { id: 706, name: "Kelly McKernan", value: "Kelly McKernan" },
  { id: 707, name: "Ken Sugimori", value: "Ken Sugimori" },
  { id: 708, name: "Kengo Kuma", value: "Kengo Kuma" },
  { id: 709, name: "Kent Monkman", value: "Kent Monkman" },
  { id: 710, name: "Kentaro Miura", value: "Kentaro Miura" },
  { id: 711, name: "Kilian Eng", value: "Kilian Eng" },
  { id: 712, name: "Kim Jung Gi", value: "Kim Jung Gi" },
  { id: 713, name: "Krenz Cushart", value: "Krenz Cushart" },
  { id: 714, name: "Kuang Hong", value: "Kuang Hong" },
  { id: 715, name: "Larry Elmore", value: "Larry Elmore" },
  { id: 716, name: "Laurel Burch", value: "Laurel Burch" },
  { id: 717, name: "Laurie Greasley", value: "Laurie Greasley" },
  { id: 718, name: "Laurie Lipton", value: "Laurie Lipton" },
  { id: 719, name: "Lawren Harris", value: "Lawren Harris" },
  { id: 720, name: "Le caravaggesque", value: "Le caravaggesque" },
  { id: 721, name: "Lee Madgwick", value: "Lee Madgwick" },
  { id: 722, name: "Leiji Matsumoto", value: "Leiji Matsumoto" },
  { id: 723, name: "Leon Kossoff", value: "Leon Kossoff" },
  { id: 724, name: "Leonardo da Vinci", value: "Leonardo da Vinci" },
  { id: 725, name: "Leonid Afremov", value: "Leonid Afremov" },
  { id: 726, name: "Lisa Frank", value: "Lisa Frank" },
  { id: 727, name: "Louis Comfort Tiffany", value: "Louis Comfort Tiffany" },
  { id: 728, name: "Louis Wain", value: "Louis Wain" },
  { id: 729, name: "Lovis Corinth", value: "Lovis Corinth" },
  { id: 730, name: "Luc Schuiten", value: "Luc Schuiten" },
  { id: 731, name: "Lucian Freud", value: "Lucian Freud" },
  { id: 732, name: "Luis Royo", value: "Luis Royo" },
  { id: 733, name: "Lyonel Feininger", value: "Lyonel Feininger" },
  { id: 734, name: "Lyubov Popova", value: "Lyubov Popova" },
  { id: 735, name: "M.C. Escher", value: "M.C. Escher" },
  { id: 736, name: "M.W. Kaluta", value: "M.W. Kaluta" },
  { id: 737, name: "Mab Graves", value: "Mab Graves" },
  { id: 738, name: "Makoto Shinkai", value: "Makoto Shinkai" },
  { id: 739, name: "Malcolm Liepke", value: "Malcolm Liepke" },
  { id: 740, name: "Man Ray", value: "Man Ray" },
  { id: 741, name: "Marc Chagall", value: "Marc Chagall" },
  { id: 742, name: "Marc Simonetti", value: "Marc Simonetti" },
  { id: 743, name: "Marcell Chagall", value: "Marcell Chagall" },
  { id: 744, name: "Marco Mazzoni", value: "Marco Mazzoni" },
  {
    id: 745,
    name: "Margaret Macdonald Mackintosh",
    value: "Margaret Macdonald Mackintosh",
  },
  { id: 746, name: "Maria Sibylla Merian", value: "Maria Sibylla Merian" },
  { id: 747, name: "Marianne North", value: "Marianne North" },
  { id: 748, name: "Marianne von Werefkin", value: "Marianne von Werefkin" },
  { id: 749, name: "Marius Borgeaud", value: "Marius Borgeaud" },
  { id: 750, name: "Mark Brooks", value: "Mark Brooks" },
  { id: 751, name: "Mark Rothko", value: "Mark Rothko" },
  { id: 752, name: "Mark Ryden", value: "Mark Ryden" },
  { id: 753, name: "Marsden Hartley", value: "Marsden Hartley" },
  { id: 754, name: "Martin Johnson Heade", value: "Martin Johnson Heade" },
  { id: 755, name: "Martiros Saryan", value: "Martiros Saryan" },
  { id: 756, name: "Mary Blair", value: "Mary Blair" },
  { id: 757, name: "Masaaki Sasamoto", value: "Masaaki Sasamoto" },
  { id: 758, name: "Masamune Shirow", value: "Masamune Shirow" },
  { id: 759, name: "Mat Collishaw", value: "Mat Collishaw" },
  { id: 760, name: "Mati Klarwein", value: "Mati Klarwein" },
  { id: 761, name: "Matt Groening", value: "Matt Groening" },
  { id: 762, name: "Matthias Grünewald", value: "Matthias Grünewald" },
  { id: 763, name: "Matti Suuronen", value: "Matti Suuronen" },
  { id: 764, name: "Maurice Sendak", value: "Maurice Sendak" },
  { id: 765, name: "Max Beckmann", value: "Max Beckmann" },
  { id: 766, name: "Max Ernst", value: "Max Ernst" },
  { id: 767, name: "Max Pechstein", value: "Max Pechstein" },
  { id: 768, name: "Max Weber", value: "Max Weber" },
  { id: 769, name: "Maxfield Parrish", value: "Maxfield Parrish" },
  { id: 770, name: "Méret Oppenheim", value: "Méret Oppenheim" },
  { id: 771, name: "Michael Deforge", value: "Michael Deforge" },
  { id: 772, name: "Michael Whelan", value: "Michael Whelan" },
  { id: 773, name: "Michelangelo", value: "Michelangelo" },
  { id: 774, name: "Michal Lisowski", value: "Michal Lisowski" },
  { id: 775, name: "Miho Hirano", value: "Miho Hirano" },
  {
    id: 776,
    name: "Mikalojus Konstantinas Ciurlionis",
    value: "Mikalojus Konstantinas Ciurlionis",
  },
  { id: 777, name: "Mike Mignola", value: "Mike Mignola" },
  { id: 778, name: "Mike Winkelmann", value: "Mike Winkelmann" },
  { id: 779, name: "Mikhail Vrubel", value: "Mikhail Vrubel" },
  { id: 780, name: "Milton Avery", value: "Milton Avery" },
  { id: 781, name: "Milton Glaser", value: "Milton Glaser" },
  { id: 782, name: "Moebius", value: "Moebius" },
  { id: 783, name: "Naoto Hattori", value: "Naoto Hattori" },
  { id: 784, name: "Natalia Goncharova", value: "Natalia Goncharova" },
  { id: 785, name: "Neil Welliver", value: "Neil Welliver" },
  { id: 786, name: "Nele Zirnite", value: "Nele Zirnite" },
  { id: 787, name: "Nicholas Roerich", value: "Nicholas Roerich" },
  { id: 788, name: "Nick Knight", value: "Nick Knight" },
  { id: 789, name: "Noah Bradley", value: "Noah Bradley" },
  { id: 790, name: "Nobuyoshi Araki", value: "Nobuyoshi Araki" },
  { id: 791, name: "Noriyoshi Ohrai", value: "Noriyoshi Ohrai" },
  { id: 792, name: "Norman Rockwell", value: "Norman Rockwell" },
  { id: 793, name: "Odd Nerdrum", value: "Odd Nerdrum" },
  { id: 794, name: "Odilon Redon", value: "Odilon Redon" },
  { id: 795, name: "Okuda San Miguel", value: "Okuda San Miguel" },
  { id: 796, name: "Oskar Kokoschka", value: "Oskar Kokoschka" },
  { id: 797, name: "Otto Dix", value: "Otto Dix" },
  {
    id: 798,
    name: "Otto Marseus van Schrieck",
    value: "Otto Marseus van Schrieck",
  },
  { id: 799, name: "Pablo Picasso", value: "Pablo Picasso" },
  { id: 800, name: "Pascal Blanché", value: "Pascal Blanché" },
  { id: 801, name: "Pascal Campion", value: "Pascal Campion" },
  { id: 802, name: "Patrick Heron", value: "Patrick Heron" },
  { id: 803, name: "Patrick Woodroffe", value: "Patrick Woodroffe" },
  { id: 804, name: "Paul Cézanne", value: "Paul Cézanne" },
  { id: 805, name: "Paul Delvaux", value: "Paul Delvaux" },
  { id: 806, name: "Paul Gauguin", value: "Paul Gauguin" },
  { id: 807, name: "Paul Gustav Fischer", value: "Paul Gustav Fischer" },
  { id: 808, name: "Paul Klee", value: "Paul Klee" },
  { id: 809, name: "Paul Lehr", value: "Paul Lehr" },
  { id: 810, name: "Paul Rand", value: "Paul Rand" },
  {
    id: 811,
    name: "Paula Modersohn-Becker",
    value: "Paula Modersohn-Becker",
  },
  { id: 812, name: "Peter Bagge", value: "Peter Bagge" },
  { id: 813, name: "Peter Elson", value: "Peter Elson" },
  { id: 814, name: "Peter Gric", value: "Peter Gric" },
  { id: 815, name: "Peter Max", value: "Peter Max" },
  { id: 816, name: "Peter Mohrbacher", value: "Peter Mohrbacher" },
  { id: 817, name: "Peter Paul Rubens", value: "Peter Paul Rubens" },
  { id: 818, name: "Phil Foglio", value: "Phil Foglio" },
  { id: 819, name: "Philip Guston", value: "Philip Guston" },
  { id: 820, name: "Philippe Druillet", value: "Philippe Druillet" },
  { id: 821, name: "Pierre-Auguste Renoir", value: "Pierre-Auguste Renoir" },
  { id: 822, name: "Piet Mondrian", value: "Piet Mondrian" },
  {
    id: 823,
    name: "Pieter Bruegel the Elder",
    value: "Pieter Bruegel the Elder",
  },
  { id: 824, name: "Pieter Claesz", value: "Pieter Claesz" },
  { id: 825, name: "Quentin Blake", value: "Quentin Blake" },
  { id: 826, name: "Rafal Olbinski", value: "Rafal Olbinski" },
  { id: 827, name: "Ralph McQuarrie", value: "Ralph McQuarrie" },
  { id: 828, name: "Randolph Caldecott", value: "Randolph Caldecott" },
  { id: 829, name: "Raphael", value: "Raphael" },
  { id: 830, name: "Raphael Lacoste", value: "Raphael Lacoste" },
  { id: 831, name: "Raphaelite", value: "Raphaelite" },
  { id: 832, name: "Ray Caesar", value: "Ray Caesar" },
  { id: 833, name: "Raymond Briggs", value: "Raymond Briggs" },
  { id: 834, name: "Raymond Swanland", value: "Raymond Swanland" },
  { id: 835, name: "Rebecca Guay", value: "Rebecca Guay" },
  { id: 836, name: "Rembrandt van Rijn", value: "Rembrandt van Rijn" },
  { id: 837, name: "Remedios Varo", value: "Remedios Varo" },
  { id: 838, name: "Rene Magritte", value: "Rene Magritte" },
  { id: 839, name: "RHADS", value: "RHADS" },
  { id: 840, name: "Richard Corben", value: "Richard Corben" },
  { id: 841, name: "Richard Dadd", value: "Richard Dadd" },
  { id: 842, name: "Richard Doyle", value: "Richard Doyle" },
  { id: 843, name: "Richard Scarry", value: "Richard Scarry" },
  { id: 844, name: "Ridley Scott", value: "Ridley Scott" },
  { id: 845, name: "Rob Gonsalves", value: "Rob Gonsalves" },
  { id: 846, name: "Rob Liefeld", value: "Rob Liefeld" },
  { id: 847, name: "Robert Bechtle", value: "Robert Bechtle" },
  { id: 848, name: "Robert Crumb", value: "Robert Crumb" },
  { id: 849, name: "Robert McCall", value: "Robert McCall" },
  { id: 850, name: "Robert Motherwell", value: "Robert Motherwell" },
  { id: 851, name: "Robert Rauschenberg", value: "Robert Rauschenberg" },
  { id: 852, name: "Robert Williams", value: "Robert Williams" },
  { id: 853, name: "Roberto Ferri", value: "Roberto Ferri" },
  { id: 854, name: "Roberto Matta", value: "Roberto Matta" },
  { id: 855, name: "Rockwell Kent", value: "Rockwell Kent" },
  { id: 856, name: "Rodney Matthews", value: "Rodney Matthews" },
  { id: 857, name: "Roger Dean", value: "Roger Dean" },
  { id: 858, name: "Ron Walotsky", value: "Ron Walotsky" },
  { id: 859, name: "Ross Tran", value: "Ross Tran" },
  { id: 860, name: "Roy Lichtenstein", value: "Roy Lichtenstein" },
  { id: 861, name: "Ruan Jia", value: "Ruan Jia" },
  { id: 862, name: "Rudolf Hausner", value: "Rudolf Hausner" },
  { id: 863, name: "Rufino Tamayo", value: "Rufino Tamayo" },
  { id: 864, name: "Russ Mills", value: "Russ Mills" },
  { id: 865, name: "Ryan Hewett", value: "Ryan Hewett" },
  { id: 866, name: "Ryohei Hase", value: "Ryohei Hase" },
  { id: 867, name: "Sailor Moon", value: "Sailor Moon" },
  { id: 868, name: "Sally Mann", value: "Sally Mann" },
  { id: 869, name: "Salvador Dalí", value: "Salvador Dalí" },
  { id: 870, name: "Sam Bosma", value: "Sam Bosma" },
  { id: 871, name: "Sam Guay", value: "Sam Guay" },
  { id: 872, name: "Sandro Botticelli", value: "Sandro Botticelli" },
  { id: 873, name: "Santiago Caruso", value: "Santiago Caruso" },
  { id: 874, name: "Satoshi Kon", value: "Satoshi Kon" },
  { id: 875, name: "Sergio Toppi", value: "Sergio Toppi" },
  { id: 876, name: "Shaun Tan", value: "Shaun Tan" },
  { id: 877, name: "Shepard Fairey", value: "Shepard Fairey" },
  { id: 878, name: "Shiki", value: "Shiki" },
  { id: 879, name: "Shinji Aramaki", value: "Shinji Aramaki" },
  { id: 880, name: "Shotaro Ishinomori", value: "Shotaro Ishinomori" },
  { id: 881, name: "Sidney Nolan", value: "Sidney Nolan" },
  { id: 882, name: "Sidney Prior Hall", value: "Sidney Prior Hall" },
  { id: 883, name: "Simon Bisley", value: "Simon Bisley" },
  { id: 884, name: "Simon Stalenhag", value: "Simon Stalenhag" },
  { id: 885, name: "Sir James Guthrie", value: "Sir James Guthrie" },
  { id: 886, name: "Sonia Delaunay", value: "Sonia Delaunay" },
  { id: 887, name: "Stanley Donwood", value: "Stanley Donwood" },
  { id: 888, name: "Stefan Gesell", value: "Stefan Gesell" },
  { id: 889, name: "Stephanie Law", value: "Stephanie Law" },
  { id: 890, name: "Stephen Gammell", value: "Stephen Gammell" },
  { id: 891, name: "Steve Argyle", value: "Steve Argyle" },
  { id: 892, name: "Steve Dillon", value: "Steve Dillon" },
  { id: 893, name: "Steve Ditko", value: "Steve Ditko" },
  { id: 894, name: "Storm Thorgerson", value: "Storm Thorgerson" },
  { id: 895, name: "Stuart Davis", value: "Stuart Davis" },
  { id: 896, name: "Studio Ghibli", value: "Studio Ghibli" },
  { id: 897, name: "Syd Mead", value: "Syd Mead" },
  { id: 898, name: "Taiyō Matsumoto", value: "Taiyō Matsumoto" },
  { id: 899, name: "Takashi Murakami", value: "Takashi Murakami" },
  { id: 900, name: "Takato Yamamoto", value: "Takato Yamamoto" },
  { id: 901, name: "Takehiko Inoue", value: "Takehiko Inoue" },
  { id: 902, name: "Taro Okamoto", value: "Taro Okamoto" },
  { id: 903, name: "Tatsuro Kiuchi", value: "Tatsuro Kiuchi" },
  { id: 904, name: "Ted Nasmith", value: "Ted Nasmith" },
  { id: 905, name: "Terry Oakes", value: "Terry Oakes" },
  { id: 906, name: "Tex Avery", value: "Tex Avery" },
  { id: 907, name: "Theodore Robinson", value: "Theodore Robinson" },
  { id: 908, name: "Thomas Cole", value: "Thomas Cole" },
  { id: 909, name: "Thomas Gainsborough", value: "Thomas Gainsborough" },
  { id: 910, name: "Thomas Kinkade", value: "Thomas Kinkade" },
  { id: 911, name: "Tibor Nagy", value: "Tibor Nagy" },
  { id: 912, name: "Tim Burton", value: "Tim Burton" },
  { id: 913, name: "Tim Doyle", value: "Tim Doyle" },
  { id: 914, name: "Tim Hildebrandt", value: "Tim Hildebrandt" },
  { id: 915, name: "Tim White", value: "Tim White" },
  { id: 916, name: "Titian", value: "Titian" },
  {
    id: 917,
    name: "Tivadar Csontváry Kosztka",
    value: "Tivadar Csontváry Kosztka",
  },
  { id: 918, name: "Todd McFarlane", value: "Todd McFarlane" },
  { id: 919, name: "Tom Bagshaw", value: "Tom Bagshaw" },
  { id: 920, name: "Tom Lovell", value: "Tom Lovell" },
  { id: 921, name: "Tom Thomson", value: "Tom Thomson" },
  { id: 922, name: "Tomer Hanuka", value: "Tomer Hanuka" },
  { id: 923, name: "Tomokazu Matsuyama", value: "Tomokazu Matsuyama" },
  { id: 924, name: "Tony DiTerlizzi", value: "Tony DiTerlizzi" },
  { id: 925, name: "Tsutomu Nihei", value: "Tsutomu Nihei" },
  { id: 926, name: "Tyler Edlin", value: "Tyler Edlin" },
  { id: 927, name: "Ub Iwerks", value: "Ub Iwerks" },
  { id: 928, name: "Victo Ngai", value: "Victo Ngai" },
  { id: 929, name: "Victor Adame Minguez", value: "Victor Adame Minguez" },
  { id: 930, name: "Victor Brauner", value: "Victor Brauner" },
  { id: 931, name: "Victor Moscoso", value: "Victor Moscoso" },
  { id: 932, name: "Victor Vasarely", value: "Victor Vasarely" },
  { id: 933, name: "Viktor Vasnetsov", value: "Viktor Vasnetsov" },
  { id: 934, name: "Vilhelm Hammershoi", value: "Vilhelm Hammershoi" },
  { id: 935, name: "Vincent Van Gogh", value: "Vincent Van Gogh" },
  { id: 936, name: "Virgil Finlay", value: "Virgil Finlay" },
  { id: 937, name: "Walt Disney", value: "Walt Disney" },
  { id: 938, name: "Walter Crane", value: "Walter Crane" },
  { id: 939, name: "Warwick Goble", value: "Warwick Goble" },
  { id: 940, name: "Wassily Kandinsky", value: "Wassily Kandinsky" },
  { id: 941, name: "Wayne Barlowe", value: "Wayne Barlowe" },
  { id: 942, name: "Wendy Froud", value: "Wendy Froud" },
  { id: 943, name: "Wes Anderson", value: "Wes Anderson" },
  { id: 944, name: "Wifredo Lam", value: "Wifredo Lam" },
  { id: 945, name: "Will Eisner", value: "Will Eisner" },
  { id: 946, name: "Willem de Kooning", value: "Willem de Kooning" },
  { id: 947, name: "Willem van Haecht", value: "Willem van Haecht" },
  { id: 948, name: "William Blake", value: "William Blake" },
  { id: 949, name: "William Gropper", value: "William Gropper" },
  { id: 950, name: "William Henry Hunt", value: "William Henry Hunt" },
  { id: 951, name: "William Hogarth", value: "William Hogarth" },
  { id: 952, name: "William Morris", value: "William Morris" },
  { id: 953, name: "William S Burroughs", value: "William S Burroughs" },
  { id: 954, name: "William Stout", value: "William Stout" },
  {
    id: 955,
    name: "William-Adolphe Bouguereau",
    value: "William-Adolphe Bouguereau",
  },
  { id: 956, name: "Winslow Homer", value: "Winslow Homer" },
  { id: 957, name: "Yaacov Agam", value: "Yaacov Agam" },
  { id: 958, name: "Yanjun Cheng", value: "Yanjun Cheng" },
  { id: 959, name: "Yayoi Kusama", value: "Yayoi Kusama" },
  { id: 960, name: "Yoji Shinkawa", value: "Yoji Shinkawa" },
  { id: 961, name: "Yoshitaka Amano", value: "Yoshitaka Amano" },
  { id: 962, name: "Yuumei", value: "Yuumei" },
  { id: 963, name: "Yves Klein", value: "Yves Klein" },
  { id: 964, name: "Yves Tanguy", value: "Yves Tanguy" },
  { id: 965, name: "Zack Snyder", value: "Zack Snyder" },
  { id: 966, name: "Zaha Hadid", value: "Zaha Hadid" },
  { id: 967, name: "Zdzislaw Beksinski", value: "Zdzislaw Beksinski" },
];

export const listFilm = [
  { id: 2, name: "Stranger Things", value: "Stranger Things" },
  { id: 4, name: "▬▬▬▬▬ Movies $ TV", value: "▬▬▬▬▬ Movies $ TV" },
  { id: 5, name: "2001 A Space Odyssey", value: "2001 A Space Odyssey" },
  { id: 6, name: "Adaptation", value: "Adaptation" },
  { id: 7, name: "Amelie", value: "Amelie" },
  { id: 8, name: "American Graffiti", value: "American Graffiti" },
  { id: 9, name: "Apocalypse Now", value: "Apocalypse Now" },
  { id: 10, name: "Arrival", value: "Arrival" },
  { id: 11, name: "Being John Malkovich", value: "Being John Malkovich" },
  { id: 12, name: "Birdman", value: "Birdman" },
  { id: 13, name: "Blade Runner", value: "Blade Runner" },
  { id: 14, name: "Brazil", value: "Brazil" },
  { id: 15, name: "Citizen Kane", value: "Citizen Kane" },
  { id: 16, name: "City of God", value: "City of God" },
  { id: 17, name: "Dark Knight", value: "Dark Knight" },
  { id: 18, name: "Dogville", value: "Dogville" },
  { id: 19, name: "Drive", value: "Drive" },
  { id: 20, name: "Dune", value: "Dune" },
  { id: 21, name: "Enemy", value: "Enemy" },
  { id: 22, name: "Enter the Void", value: "Enter the Void" },
  { id: 23, name: "Eraserhead", value: "Eraserhead" },
  {
    id: 24,
    name: "Eternal Sunshine Of The Spotless Mind",
    value: "Eternal Sunshine Of The Spotless Mind",
  },
  { id: 25, name: "Fargo", value: "Fargo" },
  { id: 26, name: "Gravity", value: "Gravity" },
  { id: 27, name: "Hook", value: "Hook" },
  { id: 28, name: "Interstellar", value: "Interstellar" },
  { id: 29, name: "It", value: "It" },
  { id: 30, name: "La La Land", value: "La La Land" },
  { id: 31, name: "Labyrinth", value: "Labyrinth" },
  { id: 32, name: "Life of Pi", value: "Life of Pi" },
  { id: 33, name: "Mad Max Fury Road", value: "Mad Max Fury Road" },
  { id: 34, name: "Metropolis", value: "Metropolis" },
  { id: 35, name: "Momento", value: "Momento" },
  { id: 36, name: "Neon Demon", value: "Neon Demon" },
  { id: 37, name: "Oblivion", value: "Oblivion" },
  { id: 38, name: "Only God Forgives", value: "Only God Forgives" },
  { id: 39, name: "Paprika", value: "Paprika" },
  { id: 40, name: "Pleasantville", value: "Pleasantville" },
  { id: 41, name: "Psycho", value: "Psycho" },
  { id: 42, name: "Pulp Fiction", value: "Pulp Fiction" },
  { id: 43, name: "Spirited Away", value: "Spirited Away" },
  { id: 44, name: "Star Wars", value: "Star Wars" },
  { id: 45, name: "Stranger Things", value: "Stranger Things" },
  { id: 46, name: "Suspiria", value: "Suspiria" },
  { id: 47, name: "The Artist", value: "The Artist" },
  { id: 48, name: "The Cell", value: "The Cell" },
  { id: 49, name: "The Fall", value: "The Fall" },
  {
    id: 50,
    name: "The Grand Budapest Hotel",
    value: "The Grand Budapest Hotel",
  },
  { id: 51, name: "The Joker", value: "The Joker" },
  {
    id: 52,
    name: "The Nightmare Before Christmas",
    value: "The Nightmare Before Christmas",
  },
  { id: 53, name: "The Revenant", value: "The Revenant" },
  { id: 54, name: "The Shape of Water", value: "The Shape of Water" },
  { id: 55, name: "The Tree of Life", value: "The Tree of Life" },
  { id: 56, name: "TRON", value: "TRON" },
  { id: 57, name: "What Dreams May Come", value: "What Dreams May Come" },
  {
    id: 58,
    name: "Who Framed Roger Rabbit",
    value: "Who Framed Roger Rabbit",
  },
];

export const listGame = [
  { id: 3, name: "Doom", value: "Doom" },
  { id: 5, name: "▬▬▬▬▬ Popular Games", value: "▬▬▬▬▬ Popular Games" },
  {
    id: 6,
    name: "Animal Crossing New Horizons",
    value: "Animal Crossing New Horizons",
  },
  { id: 7, name: "BioShock", value: "BioShock" },
  { id: 8, name: "Borderlands 2", value: "Borderlands 2" },
  { id: 9, name: "Call of Duty", value: "Call of Duty" },
  { id: 10, name: "Diablo III", value: "Diablo III" },
  { id: 11, name: "Doom", value: "Doom" },
  { id: 12, name: "Duck Hunt", value: "Duck Hunt" },
  { id: 13, name: "Elden Ring", value: "Elden Ring" },
  { id: 14, name: "Fallout", value: "Fallout" },
  { id: 15, name: "FIFA 18", value: "FIFA 18" },
  { id: 16, name: "GoldenEye 007", value: "GoldenEye 007" },
  { id: 17, name: "Gran Turismo", value: "Gran Turismo" },
  { id: 18, name: "Grand Theft Auto", value: "Grand Theft Auto" },
  { id: 19, name: "Half Life", value: "Half Life" },
  { id: 20, name: "Halo", value: "Halo" },
  { id: 21, name: "Human Fall Flat", value: "Human Fall Flat" },
  { id: 22, name: "Kinect Adventures", value: "Kinect Adventures" },
  { id: 23, name: "LittleBigPlanet", value: "LittleBigPlanet" },
  { id: 24, name: "Mario Kart", value: "Mario Kart" },
  { id: 25, name: "Metal Gear Solid", value: "Metal Gear Solid" },
  { id: 26, name: "Metroid Prime", value: "Metroid Prime" },
  { id: 27, name: "Minecraft", value: "Minecraft" },
  { id: 28, name: "Nintendogs", value: "Nintendogs" },
  { id: 29, name: "Pac-Man", value: "Pac-Man" },
  { id: 30, name: "Perfect Dark", value: "Perfect Dark" },
  { id: 31, name: "Pokémon", value: "Pokémon" },
  { id: 32, name: "Portal", value: "Portal" },
  { id: 33, name: "PUBG Battlegrounds", value: "PUBG Battlegrounds" },
  { id: 34, name: "Quake", value: "Quake" },
  { id: 35, name: "Red Dead Redemption", value: "Red Dead Redemption" },
  { id: 36, name: "Resident Evil", value: "Resident Evil" },
  { id: 37, name: "Skyrim", value: "Skyrim" },
  { id: 38, name: "Sonic the Hedgehog", value: "Sonic the Hedgehog" },
  { id: 39, name: "Super Mario 64", value: "Super Mario 64" },
  { id: 40, name: "Tekken 3", value: "Tekken 3" },
  { id: 41, name: "Terraria", value: "Terraria" },
  { id: 42, name: "Tetris", value: "Tetris" },
  {
    id: 43,
    name: "The Elder Scrolls V Skyrim",
    value: "The Elder Scrolls V Skyrim",
  },
  {
    id: 44,
    name: "The Legend of Zelda Majora's Mask",
    value: "The Legend of Zelda Majora's Mask",
  },
  {
    id: 45,
    name: "The Legend of Zelda Ocarina of Time",
    value: "The Legend of Zelda Ocarina of Time",
  },
  { id: 46, name: "The Witcher 3", value: "The Witcher 3" },
  { id: 47, name: "Tony Hawk's Pro Skater", value: "Tony Hawk's Pro Skater" },
  { id: 48, name: "Wii Fit", value: "Wii Fit" },
  { id: 49, name: "Wii Play", value: "Wii Play" },
  { id: 50, name: "Wii Sports", value: "Wii Sports" },
  { id: 51, name: "World of Goo", value: "World of Goo" },
  { id: 52, name: "Worms World Party", value: "Worms World Party" },
];

export const listDepthOfField = [
  { id: 1, name: "No Preference", value: "" },
  { id: 2, name: "Deep (full focus)", value: "defocus," },
  { id: 3, name: "Shallow (focal blur)", value: "dof" },
];
