import './Home.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import bgBanner from '~/asset/images/bg-banner.png';
import { Toolbar, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
//import DirectionsIcon from '@mui/icons-material/Directions';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';

const apiUrl = process.env.REACT_APP_API_URL;
const baseImg = process.env.REACT_APP_BASE_IMG;

const categories = ['Beautiful', 'Photography', 'Amine', 'Paint', '8k', 'Human', 'Color', 'Fairy', 'Fantasy'];

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

const theme = createTheme({
    palette: {
        primary: {
            main: '#000',
        },
    },
});
export default function Home() {
    const [searchKeyword, setSearchKeyword] = useState('');
    const [keyword, setKeyword] = useState('');
    const [searchSubmit, setSearchSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingStart, setIsLoadingStart] = useState(false);

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 20;

    const isMobile = () => {
        const width = window.innerWidth; // || document.documentElement.clientWidth || document.body.clientWidth;
        return width < 600;
    };

    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + window.pageYOffset === document.documentElement.offsetHeight ||
                window.innerHeight + window.pageYOffset + 0.5 === document.documentElement.offsetHeight
            ) {
                setIsLoading(true);
                setCurrentPage(currentPage + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [currentPage]);

    useEffect(() => {
        setIsLoading(true);
        if (currentPage === 1) {
            setIsLoadingStart(true);
        }

        axios
            .get(`${apiUrl}/api/prompts?limit=${perPage}&page=${currentPage}&keyword=${keyword}`)
            .then((res) => {
                setData([...data, ...res.data.data.result]);
                setIsLoading(false);
                if (currentPage === 1) {
                    setIsLoadingStart(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, searchSubmit, keyword]);

    const changeSearchKeyword = (e) => {
        setSearchKeyword(e.target.value);
    };
    const handleSubmitSearch = (event) => {
        event.preventDefault();
        setCurrentPage(1);
        setSearchSubmit(!searchSubmit);
        setKeyword(searchKeyword);
        setData([]);
    };

    return (
        <ThemeProvider theme={theme}>
            <main>
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                    }}
                >
                    <Container maxWidth="100%" style={{ backgroundImage: `url(${bgBanner})` }}>
                        <Box
                            sx={{ height: isMobile() ? '25vh' : '50vh', justifyContent: 'center' }}
                            className="btn--search"
                        >
                            <Stack spacing={2}>
                                <Item
                                    component="form"
                                    onSubmit={handleSubmitSearch}
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                                    style={{ borderRadius: '50px' }}
                                >
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Search ..."
                                        inputProps={{ 'aria-label': searchKeyword }}
                                        defaultValue={searchKeyword}
                                        onChange={changeSearchKeyword}
                                        value={searchKeyword}
                                    />
                                    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                    {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                                    {/* <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                                        <DirectionsIcon />
                                    </IconButton> */}
                                </Item>
                                {/* <Item style={{ background: 'none', color: '#FFFFFF', textAlign: 'left' }}>
                                    <Typography>Trend: valentines day, cat, money, travel, sunset</Typography>
                                </Item> */}
                            </Stack>
                        </Box>
                    </Container>

                    <Container maxWidth="lg">
                        <Typography sx={{ py: 2 }} style={{ fontSize: isMobile() ? '16px' : '20px' }} variant="h1">
                            Explore the world's imagination. AI Image Generator empowers anyone to create beautiful art
                            and images in seconds.
                        </Typography>
                        <Toolbar>
                            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={0.5}>
                                <Item variant="text" className="button--page">
                                    <Button
                                        variant="text"
                                        color="inherit"
                                        style={{
                                            textTransform: 'none',
                                            color: '#454545',
                                            fontWeight: 'bold',
                                        }}
                                        onClick={() => {
                                            setSearchKeyword(null);
                                            setSearchSubmit(!searchSubmit);
                                            setCurrentPage(1);
                                            setData([]);
                                        }}
                                    >
                                        Category
                                    </Button>
                                </Item>
                                <Item variant="text" className="button--page">
                                    {categories.map((category) => (
                                        <Button
                                            variant="text"
                                            color="inherit"
                                            style={{ textTransform: 'none', color: '#454545' }}
                                            onClick={() => {
                                                setSearchKeyword(category);
                                                setSearchSubmit(!searchSubmit);
                                                setCurrentPage(1);
                                                setData([]);
                                            }}
                                        >
                                            {category}
                                        </Button>
                                    ))}
                                </Item>
                            </Stack>
                        </Toolbar>
                    </Container>

                    <Container sx={{ py: 2 }} maxWidth="lg">
                        {isLoadingStart && <div className="btn--container">{<CircularProgress />}</div>}

                        <Grid container>
                            <ImageList cols={isMobile() ? 2 : 4} gap={20} className="modal--container">
                                {data.slice(0, currentPage * perPage).map((post) => (
                                    <ImageListItem key={post.id} className="image--container">
                                        <img
                                            alt={post.name}
                                            src={`${baseImg}/${post.url.split('full_jpg/')[1]}`}
                                            srcSet={`${baseImg}/${post.url.split('full_jpg/')[1]}`}
                                            loading="lazy"
                                        />
                                        <div className="hover--prompt">
                                            <p className="text--image">{post.name}</p>
                                            {!isMobile() && (
                                                <div className="btn--hover">
                                                    <Stack direction="row" spacing={1}>
                                                        <div className="btn--hover__content">
                                                            <Chip
                                                                label="AI Gallery"
                                                                avatar={
                                                                    <Avatar
                                                                        alt="AI Gallery"
                                                                        src="https://mui.com/static/images/avatar/1.jpg"
                                                                    />
                                                                }
                                                            />
                                                        </div>
                                                        <div className="btn--hover__content">
                                                            <IconButton
                                                                size="small"
                                                                color="primary"
                                                                className="btn--hover__icon"
                                                            >
                                                                <FavoriteIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                size="small"
                                                                color="primary"
                                                                className="btn--hover__icon"
                                                            >
                                                                <FileDownloadRoundedIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                size="small"
                                                                color="primary"
                                                                className="btn--hover__icon"
                                                            >
                                                                <ContentCopyIcon />
                                                            </IconButton>
                                                        </div>
                                                    </Stack>
                                                </div>
                                            )}
                                        </div>
                                    </ImageListItem>
                                ))}
                                {isLoading && (
                                    <>
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                        <Skeleton variant="rectangular" height={isMobile() ? 371.1 : 409.5} />
                                    </>
                                )}
                            </ImageList>
                        </Grid>

                        <div className="btn--container">{isLoading && <CircularProgress />}</div>
                    </Container>
                </Box>
            </main>
        </ThemeProvider>
    );
}
