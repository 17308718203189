import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

function Footer() {
    return (
        <>
            <Box sx={{ bgcolor: '#F5F5F5', p: 4 }} component="footer">
                <div className="font--button">
                    <Link color="secondary" style={{ textDecoration: 'none' }} to="/">
                        <Button
                            sx={{
                                fontFamily: 'iCiel Queulat Uni',
                                fontSize: '16px',
                                fontWeight: '900',
                                color: '#0C0E2E',
                                width: '100px',
                                marginLeft: '300px',
                                height: '30px',
                            }}
                        >
                            ai art gallery
                        </Button>
                    </Link>
                </div>
                <Box
                    sx={{
                        paddingTop: '20px',
                        display: 'flex',
                        justifyContent: 'space-around',
                        fontSize: '14px',
                        lineHeight: '22px',
                    }}
                >
                    <Breadcrumbs aria-label="breadcrumb" color="#454545">
                        <Link underline="hover" color="inherit" href="#">
                            / Blog
                        </Link>
                        <Link underline="hover" color="inherit" href="#">
                            Guideline
                        </Link>
                        <Link underline="hover" color="inherit" href="#">
                            Contact
                        </Link>
                        <Link underline="hover" color="inherit" href="#">
                            Subcrible
                        </Link>
                    </Breadcrumbs>

                    <Breadcrumbs aria-label="breadcrumb" color="#454545">
                        <Link underline="hover" color="inherit" href="#">
                            / Facebook
                        </Link>
                        <Link underline="hover" color="inherit" href="#">
                            Twitter
                        </Link>
                        <Link underline="hover" color="inherit" href="#">
                            Instagram
                        </Link>
                    </Breadcrumbs>
                </Box>
            </Box>
        </>
    );
}

export default Footer;
