// Pages
import Home from '~/pages/Home/Home';
import About from '~/pages/About/About';
import PromptBuilder from '~/pages/PromptBuilder/PromptBuilder';

// Public routes
const publicRoutes = [
    { id: 1, path: '/', component: Home, name: 'Home', showMenu: false },
    { id: 2, path: '/prompt-builder', component: PromptBuilder, name: 'Prompt Builder', showMenu: true },
    { id: 3, path: '/about', component: About, name: 'About', showMenu: true },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
