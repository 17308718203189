import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
// import MenuItem from '@mui/material/MenuItem';
// import PersonIcon from '@mui/icons-material/Person';
import { Link } from 'react-router-dom';
import { publicRoutes } from '~/routes';

function Header() {
    // const [anchorEl, setAnchorEl] = useState(null);
    const [menuCurrent, setMenuCurrent] = useState(1);
    // const open = Boolean(anchorEl);
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    return (
        <AppBar
            position="static"
            sx={{
                backgroundColor: '#ffffff',
            }}
        >
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    <div className="font--button">
                        <Link color="secondary" style={{ textDecoration: 'none' }} to="/">
                            <Button
                                sx={{
                                    fontFamily: 'iCiel Queulat Uni',
                                    fontWeight: 900,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    color: '#0C0E2E',
                                    width: '100px',
                                }}
                            >
                                ai art gallery
                            </Button>
                        </Link>
                    </div>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'flex-end' } }}>
                        {publicRoutes.map((route) => (
                            <Button
                                key={route.id}
                                size="small"
                                sx={{
                                    my: 2,
                                    color: '#454545',
                                    display: 'block',
                                    fontSize: '12px',
                                    fontWeight: menuCurrent === route.id ? 'bold' : '',
                                }}
                                style={{ textTransform: 'none' }}
                                onClick={() => {
                                    setMenuCurrent(route.id);
                                }}
                            >
                                <Link style={{ textDecoration: 'none', color: '#454545' }} to={route.path}>
                                    {route.name}
                                </Link>
                            </Button>
                        ))}
                    </Box>
                    {/* <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        sx={{
                            color: '#000000',
                        }}
                        disableRipple={true}
                    >
                        <PersonIcon />
                    </Button> */}
                    {/* <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem>
                        <MenuItem onClick={handleClose}>Logout</MenuItem>
                    </Menu> */}
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default Header;
