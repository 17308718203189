import Header from '~/components/Layout/components/Header/Header';
import Footer from '~/components/Layout/components/Footer/Footer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme();

function DefaultLayout({ children }) {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <Header position="relative"></Header>
            <div className="container">
                <div className="content">{children}</div>
            </div>
            <Footer />
        </ThemeProvider>
    );
}

export default DefaultLayout;
